import {Tag} from "../../api/dto";
import {FC} from "react";

export const TagPill: FC<{tag: Tag, size?: 'xs'|'sm'|'md'|'lg'}> = props => {
  const style = getStyle(props.tag.color)
  const sizeStyle = getSizeStyle(props.size ?? 'md')
  return <span className={`${style} ${sizeStyle} rounded-full font-medium`}>{props.tag.name}</span>
}

const getSizeStyle = (size: 'xs'|'sm'|'md'|'lg') => {
  switch (size) {
    case 'xs':
      return 'px-2 py-0 text-xs'
    case 'sm':
      return 'px-3 py-1 text-sm'
    case 'md':
      return 'px-3 py-1 text-base'
    case 'lg':
      return 'px-4 py-2 text-lg'
  }
}

const getStyle = (color: Tag['color']) => {
  switch (color) {
    case 'red':
      return 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'
      // return 'bg-gradient-to-b text-rose-900 from-rose-300 to-red-400 dark:text-rose-200 dark:from-rose-800 dark:to-red-700';
    case 'orange':
      return 'bg-orange-100 text-orange-800 dark:bg-orange-800 dark:text-orange-100'
      // return 'bg-gradient-to-b text-amber-900 from-amber-300 to-orange-400 dark:text-amber-200 dark:from-amber-800 dark:to-orange-700'
    case 'amber':
      return 'bg-amber-100 text-amber-800 dark:bg-amber-800 dark:text-amber-100'
      // return 'bg-gradient-to-b text-yellow-900 from-yellow-300 to-amber-400 dark:text-yellow-200 dark:from-yellow-800 dark:to-amber-700'
    case 'yellow':
      return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100'
      // return 'bg-gradient-to-b text-amber-900 from-amber-100 to-yellow-400 dark:text-amber-200 dark:from-amber-900 dark:to-yellow-700'
    case 'lime':
      return 'bg-lime-100 text-lime-800 dark:bg-lime-800 dark:text-lime-100'
      // return 'bg-gradient-to-b text-green-900 from-green-300 to-lime-400 dark:text-green-200 dark:from-green-800 dark:to-lime-700'
    case 'green':
      return 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
      // return 'bg-gradient-to-b text-emerald-900 from-emerald-300 to-green-400 dark:text-emerald-200 dark:from-emerald-800 dark:to-green-700'
    case 'emerald':
      return 'bg-emerald-100 text-emerald-800 dark:bg-emerald-800 dark:text-emerald-100'
      // return 'bg-gradient-to-b text-teal-900 from-teal-300 to-emerald-400 dark:text-teal-200 dark:from-teal-800 dark:to-emerald-700'
    case 'teal':
      return 'bg-teal-100 text-teal-800 dark:bg-teal-800 dark:text-teal-100'
      // return 'bg-gradient-to-b text-cyan-900 from-cyan-300 to-teal-400 dark:text-cyan-200 dark:from-cyan-800 dark:to-teal-700'
    case 'cyan':
      return 'bg-cyan-100 text-cyan-800 dark:bg-cyan-800 dark:text-cyan-100'
      // return 'bg-gradient-to-b text-sky-900 from-sky-100 to-cyan-300 dark:text-sky-200 dark:from-sky-900 dark:to-cyan-800';
    case 'sky':
      return 'bg-sky-100 text-sky-800 dark:bg-sky-800 dark:text-sky-100'
      // return 'bg-gradient-to-b text-blue-900 from-blue-100 to-sky-300 dark:text-blue-200 dark:from-blue-900 dark:to-sky-800';
    case 'blue':
      return 'bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100'
      // return 'bg-gradient-to-b text-indigo-900 from-indigo-100 to-blue-300 dark:text-indigo-200 dark:from-indigo-900 dark:to-blue-800';
    case 'indigo':
      return 'bg-indigo-100 text-indigo-800 dark:bg-indigo-800 dark:text-indigo-100'
      // return 'bg-gradient-to-b text-violet-900 from-violet-100 to-indigo-300 dark:text-violet-200 dark:from-violet-900 dark:to-indigo-800';
    case 'violet':
      return 'bg-violet-100 text-violet-800 dark:bg-violet-800 dark:text-violet-100'
      // return 'bg-gradient-to-b text-purple-900 from-purple-300 to-violet-400 dark:text-purple-200 dark:from-purple-800 dark:to-violet-700';
    case 'purple':
      return 'bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-100'
      // return 'bg-gradient-to-b text-fuchsia-900 from-fuchsia-300 to-purple-300 dark:text-fuchsia-200 dark:from-fuchsia-800 dark:to-purple-800';
    case 'fuchsia':
      return 'bg-fuchsia-100 text-fuchsia-800 dark:bg-fuchsia-800 dark:text-fuchsia-100'
      // return 'bg-gradient-to-b text-purple-900 from-purple-100 to-fuchsia-300 dark:text-purple-200 dark:from-purple-900 dark:to-fuchsia-800';
    case 'pink':
      return 'bg-pink-100 text-pink-800 dark:bg-pink-800 dark:text-pink-100'
      // return 'bg-gradient-to-b text-rose-900 from-rose-100 to-pink-300 dark:text-rose-200 dark:from-rose-900 dark:to-pink-800';
    case 'rose':
      return 'bg-rose-100 text-rose-800 dark:bg-rose-800 dark:text-rose-100'
      // return 'bg-gradient-to-b text-red-900 from-red-100 to-rose-400 dark:text-red-200 dark:from-red-900 dark:to-rose-700';
  }
}