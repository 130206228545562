import React, {FC, useCallback, useState} from "react";
import {PageIconHeader} from "../../components/layout/PageIconHeader";
import {faExclamationCircle, faShop, faWarning} from "@fortawesome/free-solid-svg-icons";
import {Breadcrumbs} from "../../components/content/Breadcrumbs";
import {PageHeader} from "../../components/content/PageHeader";
import {ContentContainer} from "../../components/content/ContentContainer";
import {ErrorBag, useApiCall, ValidationError} from "../../api/api";
import {useFetchedResource} from "../../api/APIContext";
import {useRefresh, useRefreshEffect} from "../../components/RefreshController";
import {useTenant} from "../../tenant/TenantContext";
import {Integration} from "../../api/dto";
import {Input} from "../../components/form/Input";
import {Button} from "../../components/form/Button";
import {TabContainer} from "../../components/layout/TabContainer";
import {Callout} from "../../components/content/Callout";
import {Skeleton} from "../../components/data/Skeleton";
import {SectionHeader} from "../../components/content/SectionHeader";
import { Paragraph } from "../../components/Paragraph";
import {Card} from "../../components/Card";
import {InputErrors} from "../../components/form/InputErrors";

export const Slack: FC = () => {
  const { getIntegrations } = useApiCall();
  const {tenant} = useTenant()
  const { resource: integrationResource, loading: loadIntegrations, reload: reloadIntegrations } = useFetchedResource(getIntegrations);
  useRefreshEffect(() => {
    reloadIntegrations(undefined);
  });
  if (loadIntegrations) {
    return <ContentContainer size={"md"}>
      <PageIconHeader icon={faShop} backButton={true}>
        <Breadcrumbs crumbs={[
          {label: "Marketplace", href: `/${tenant}/marketplace`}
        ]} currentPage={"Slack"} />
        <PageHeader>Slack</PageHeader>
      </PageIconHeader>
      <Skeleton type={'card'}/>
    </ContentContainer>
  }
  if (!integrationResource) {
    return <Callout color={'danger'} icon={faExclamationCircle}>Er is iets verkeerd gegaan.</Callout>;
  }
  const integration = integrationResource.find(item => item.integration === 'slack');
  return (
    <ContentContainer size={"md"}>
      <PageIconHeader icon={faShop} backButton={true}>
        <Breadcrumbs crumbs={[
          {label: "Marketplace", href: `/${tenant}/marketplace`}
        ]} currentPage={"Slack"} />
        <PageHeader>{integration ? integration.name : ''}</PageHeader>
      </PageIconHeader>

      {integration && <IntegrationContent integration={integration} />}
    </ContentContainer>
  )
}

const IntegrationContent: FC<{integration: Integration}> = (props) => {
  return <TabContainer tabs={[
    {name: 'Details & Informatie', body: <IntegrationDetails integration={props.integration}/>},
    {name: 'Instellingen', body: <IntegrationConfig integration={props.integration}/>}
  ]} />
}

const IntegrationDetails: FC<{ integration: Integration }> = (props) => {
  return <>
    <div className={"mb-8"}>
      <SectionHeader>Omschrijving</SectionHeader>
      <Paragraph>Slack is een cloud-gebaseerde samenwerkings- en communicatietool die wordt gebruikt door teams en bedrijven om hun communicatie te centraliseren en te organiseren.</Paragraph>
    </div>
  </>
}

const IntegrationConfig: FC<{ integration: Integration }> = (props) => {
  const {postIntegration} = useApiCall()
  const refresh = useRefresh()
  const [options, setOptions] = useState(props.integration.required_options);
  const [errors, setErrors] = useState<ErrorBag>({});

  const handleInputChange = (index: number, newValue: string) => {
    const newOptions = [...options];
    newOptions[index].value = newValue;
    setOptions(newOptions);
  };

  const save = useCallback(async () => {
    await postIntegration(props.integration.integration, options).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    refresh()
  }, [options]);

  const getType = (type: string): 'text'|'password' => {
    switch (type) {
      case 'string':
        return 'text';
      case 'encrypted_string':
        return 'password';
      default:
        return 'text';
    }
  };

  return (
    <>
      {!props.integration.configured && <>
        <Callout color={'warning'} icon={faWarning}>
          Deze integratie is nog niet ingesteld. Stel hem hieronder in
        </Callout>
      </>}

      {props.integration.configured && <>
        <div
          className={"px-3 py-4 border border-brand-300 dark:border-brand-700 bg-brand-100 dark:bg-brand-600 rounded"}>
          <h1>Een connectie tussen Windesk en Slack is gemaakt</h1>
        </div>
      </>}

      <Card title={props.integration.configured ? "Integratie opnieuw instellen" : 'Integratie setup'}>
        <div className={"text-sm"}>
          <strong className={"mb-2 block"}>Vraag aan de administrator van de Slack omgeving om een Webhook URL te
            genereren en deze hier in te
            stellen</strong>
          <ul className={"list-disc pl-6 mb-3"}>
            <li>Mocht jij dit zelf zijn. Navigeer dan in Slack naar de Instellingen van jouw omgeving</li>
            <li>Navigeer vervolgens naar 'Tools & settings' en dan naar 'Manage Apps'</li>
            <li>Klik hier 'Custom Integrations' en selecteer 'Incomming WebHooks'</li>
            <li>Klik op 'Add to slack' en selecteer het kanaal waar je slack notificaties wilt ontvangen</li>
            <li>Kopieer de zojuist gegenereerde Webhook URL in Windesk.</li>
          </ul>
        </div>
        <form className={'flex flex-col'}>
          <InputErrors errors={errors} field={'message'} />
          {options.map((option, i) => (
            <div className={"mt-2 mb-4 flex items-center"} key={i}>
              <Input type={getType(option.type)} label={option.key} value={option.value!}
                     onChange={(e: string) => handleInputChange(i, e)}/>
              <InputErrors errors={errors} field={option.key} />
            </div>
          ))}
        </form>
        <Button type={'primary'} size={'md'} text={'Opslaan'} onClick={save}/>
      </Card>
    </>
  );
};