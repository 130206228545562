import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Select} from "../components/form/Select";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {InputErrors} from "../components/form/InputErrors";
import {SectionHeader} from "../components/content/SectionHeader";
import {SectionDivider} from "../components/content/SectionDivider";

export const AddCustomerModal: FC<{}> = (props) => {
  const {addCustomer} = useApiCall()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [url, setUrl] = useState('')
  const [streetname, setStreetname] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')
  const [referenceNr, setReferenceNr] = useState('')
  const [contactName, setContactName] = useState('')
  const [errors, setErrors] = useState<ErrorBag>({})
  let address = '';


  if (streetname === '' && postalCode === '' && city === '') {
    address = '';

  } else {
    address = `${streetname}, ${postalCode} ${city}`;
  }



  const reload = useRefresh()

  const save = useCallback( async () => {
    const deployment = await addCustomer({
      name: name,
      contact_person: contactName,
      email: email,
      phone: phone,
      url: url,
      address: address,
      reference_number: referenceNr,
      }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [name, contactName, email, phone, url, streetname, postalCode, city, referenceNr])

  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1 dark:bg-zinc-700"}>
      <SectionHeader>Bedrijfs informatie</SectionHeader>
      <div className={'flex flex-col'}>
        <div className={"flex items-center space-x-4"}>
          <div className={"basis-1/2"}>
            <Input label={"Bedrijfsnaam"} required={true} type={"text"} value={name} placeholder={"bedrijfsnaam"}
                   onChange={(v) => setName(v)}/>
          </div>
          <div className={"basis-1/2"}>
            <Input label={"Reference number"} type={"text"} value={referenceNr} placeholder={"Referentie nummer"}
                   onChange={(v) => setReferenceNr(v)}/>
          </div>
        </div>
        <InputErrors errors={errors} field={'name'}/>
        <InputErrors errors={errors} field={'reference_number'}/>
      </div>

      <div className={"items-center space-x-4"}>
        <Input label={"Straat"} type={"text"} value={streetname} placeholder={"Straat"} onChange={(v) => setStreetname(v)}/>
      </div>
      <div className={"flex items-center space-x-4"}>
        <div className={"basis-2/5"}>
          <Input label={"Postcode"} type={"text"} value={postalCode} placeholder={"Postcode"} onChange={(v) => setPostalCode(v)}/>
        </div>
        <div className={"basis-3/5"}>
          <Input label={"Stad"} type={"text"} value={city} placeholder={"Stad"} onChange={(v) => setCity(v)}/>
        </div>
      </div>
      <InputErrors errors={errors} field={'address'}/>
      <div className={"items-center space-x-4"}>
        <Input label={"Website"} type={"text"} value={url} placeholder={"Website url"} onChange={(v) => setUrl(v)}/>
        <InputErrors errors={errors} field={'url'}/>
      </div>


      <SectionDivider/>
      <SectionHeader>Informatie Contactpersoon</SectionHeader>
      <div className={"items-center space-x-4"}>
        <Input label={"Contact persoon"} required={true} type={"text"} value={contactName} placeholder={"Naam"}
               onChange={(v) => setContactName(v)}/>
        <InputErrors errors={errors} field={'contact_person'}/>
      </div>
      <div className={"items-center space-x-4"}>
        <Input label={"email"} required={true} type={"text"} value={email} placeholder={"E-mail"}
               onChange={(v) => setEmail(v)}/>
        <InputErrors errors={errors} field={'email'}/>
      </div>
      <div className={"items-center space-x-4"}>
        <Input label={"Telefoon"} type={"text"} value={phone} placeholder={"Telefoon nummer"}
               onChange={(v) => setPhone(v)}/>
        <InputErrors errors={errors} field={'phone'}/>
      </div>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}