import {FC, useCallback, useState} from "react";
import {Customer, Tenant, User} from "../api/dto";
import {Input} from "../components/form/Input";
import {InputErrors} from "../components/form/InputErrors";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";

export const EditContactModal: FC<{customer: Customer, contact: User}> = (props) => {
  const {addCustomerUser, editCustomerUser} = useApiCall()
  const [firstName, setFirstName] = useState(props.contact.first_name)
  const [middleName, setMiddleName] = useState(props.contact.middle_name)
  const [lastName, setLastName] = useState(props.contact.last_name)
  const [email, setEmail] = useState(props.contact.email)
  const [secondaryEmail, setSecondaryEmail] = useState(props.contact.secondary_email)
  const [phone, setPhone] = useState(props.contact.phone)
  const [position, setPosition] = useState(props.contact.position)
  const [remark, setRemark] = useState(props.contact.remark)
  const [errors, setErrors] = useState<ErrorBag>({})

  const reload = useRefresh()

  const save = useCallback(async () => {
    await editCustomerUser(props.customer.id, props.contact.id, firstName, middleName, lastName, email, secondaryEmail, position, remark, phone).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload ()
  }, [firstName, middleName, lastName, email, secondaryEmail, phone, position, remark])

  return <>
    <form onSubmit={() => save} className={'space-y-4 flex-1'}>
      {/* Name */}
      <div className={"flex space-x-3"}>
        <div className={"basis-2/5"}>
          <Input type={'text'} label={'Voornaam'} value={firstName} onChange={(v) => setFirstName(v)} placeholder={'John'}/>
          <InputErrors errors={errors} field={'first_name'}/>
        </div>
        <div className={"basis-1/5"}>
          <Input type={'text'} label={'Tussenvoegsel'} value={middleName} onChange={(v) => setMiddleName(v)} placeholder={'van'}/>
          <InputErrors errors={errors} field={'middle_name'}/>
        </div>
        <div className={"basis-2/5"}>
          <Input type={'text'} label={'Achternaam'} value={lastName} onChange={(v) => setLastName(v)} placeholder={'Doe'}/>
          <InputErrors errors={errors} field={'last_name'}/>
        </div>
      </div>
      {/* Job position */}
      <div>
        <Input type={"text"} label={"Functie"} value={position} onChange={(v) => setPosition(v)} placeholder={'Sales manager'}/>
        <InputErrors errors={errors} field={'position'}/>
      </div>
      <div className={'flex space-x-3'}>
        {/* Email */}
        <div className={'basis-1/2'}>
          <Input type={"text"} label={'E-mail'} value={email} onChange={(v) => setEmail(v)} required={true} placeholder={'placeholder@gmail.com'}/>
          <InputErrors errors={errors} field={'email'}/>
        </div>
        {/* Secondary email */}
        <div className={'basis-1/2'}>
          <Input type={"text"} label={'2e E-mail (optioneel)'} value={secondaryEmail} onChange={(v) => setSecondaryEmail(v)} placeholder={'placeholder@gmail.com'}/>
          <InputErrors errors={errors} field={'secondary_email'}/>
        </div>
      </div>
      {/* phone */}
      <div>
        <Input type={'text'} label={'Telefoon'} value={phone} onChange={(v) => setPhone(v)} placeholder={'+31 123456789'}/>
        <InputErrors errors={errors} field={'phone'}/>
      </div>
      {/* Remark */}
      <div>
        <Input type={"textarea"} label={'Opmerking'} value={remark} onChange={(v) => setRemark(v)}/>
        <InputErrors errors={errors} field={'remark'}/>
      </div>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}