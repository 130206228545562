import React, {useEffect, useMemo, useState} from "react";
import {SearchInput} from "./SearchInput";
import {IconButton} from "../form/Button";
import * as fa from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {usePersistentState} from "../../util/usePersistentState";
import {Select} from "../form/Select";
import {faSearch, faTimesCircle} from "@fortawesome/free-solid-svg-icons";

interface SearchAndPaginateProps<T> {
  data: T[]
  filterProperties: (keyof T)[]
  resultsBuilder: (results: T[]) => JSX.Element
  onReload?: () => void
  paginationEnabled?: boolean
  placeholder?: string
}

const PAGINATION_BUTTON_LIMIT = 3
const PAGINATION_LIMIT_DEFAULT = 25
const PAGINATION_LIMIT_OPTIONS = [10, 25, 50, 100]

export function SearchPaginateReload<T>(props: SearchAndPaginateProps<T>): JSX.Element {
  const [search, setSearch] = React.useState("")
  const filteredData = React.useMemo(() => {
    if (search === "") {
      return props.data
    }
    return props.data.filter(item => {
      const values = props.filterProperties?.map((key) => {
        return item[key]
      }) ?? []
      return values.some(v => JSON.stringify(v).toLowerCase().includes(search.toLowerCase()))
    })
  }, [props.data, props.filterProperties, search])

  const paginationEnabled = useMemo(() => {
    return !! props.paginationEnabled
  }, [props.paginationEnabled])

  const [paginationLimit, setPaginationLimit] = usePersistentState<number>("paginationLimit", PAGINATION_LIMIT_DEFAULT)

  const [page, setPage] = useState(0)
  const pageCount = useMemo(() => {
    return Math.ceil(filteredData.length / paginationLimit)
  }, [paginationLimit, filteredData])
  useEffect(() => {
    if (page >= pageCount && pageCount > 0) {
      setPage(pageCount - 1)
    } else if (page < 0) {
      setPage(0)
    }
  }, [page, pageCount]);
  const filteredAndPaginatedData = useMemo(() => {
    if (!paginationEnabled) {
      return filteredData
    }
    return filteredData.slice(page * paginationLimit, (page + 1) * paginationLimit)
  }, [page, paginationEnabled, filteredData, paginationLimit])
  const pages = useMemo(() => {
    const pages = []
    for (let i= 0; i < pageCount; i++) {
      pages.push(i)
    }
    if (pageCount <= PAGINATION_BUTTON_LIMIT) {
      return pages
    }
    // TODO: When a list is very long, only render a set number of buttons based on PAGINATION_BUTTON_LIMIT
    return pages
  }, [pageCount, page])

  const paginationButtonBaseStyle = 'rounded h-8 w-8 flex items-center justify-center font-medium'
  return <div>
    <div className={"flex items-end justify-between mb-4"}>
      <div className={"flex-1"}>
        {/*search*/}
        <label
          className={`-ml-[1px] -my-[1px] h-12 flex-1 bg-white dark:bg-zinc-700 border rounded ${paginationEnabled ? 'xl:rounded-r-none  mr-5' : ''} border-slate-200 dark:border-zinc-600 flex items-center group focus-within:border-brand-600 focus-within:text-brand-800 focus-within:dark:text-white px-4 py-3`}>
          <FontAwesomeIcon icon={faSearch} className={"mr-3 text-slate-600 dark:text-zinc-300"}/>
          <input
            type="text"
            placeholder={props.placeholder}
            className={"rounded h-8 flex-1 outline-none dark:bg-zinc-700"}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {search.length > 0 && <button
            className={"h-8 w-8 flex items-center justify-center text-slate-400 dark:text-zinc-300 hover:text-brand-600 hover:dark:text-zinc-400"}
            onClick={() => setSearch('')}>
            <FontAwesomeIcon icon={faTimesCircle} className={"dark:text-zinc-400 hover:dark:text-brand-500"}/>
          </button>}
        </label>
        {/*<SearchInput value={search} focus onChange={setSearch}/>*/}
      </div>
      {paginationEnabled && <div className={'mr-2'}>
        <Select label={'Per page'} inline
                options={Object.fromEntries(PAGINATION_LIMIT_OPTIONS.map(i => [String(i), String(i)]))}
                value={String(paginationLimit)} onChange={(newLimit) => setPaginationLimit(Number(newLimit))}/>
      </div>}
      {props.onReload ?
        <IconButton icon={fa.faRefresh} onClick={props.onReload} type={"secondary"} size={"sm"}/> : null}
    </div>
    <div>
      {props.resultsBuilder(filteredAndPaginatedData)}
    </div>
    {paginationEnabled && pageCount > 1 ? <div className={"flex"}>
      <div
        className={"flex items-center justify-start mt-4 bg-white dark:bg-zinc-700 border border-slate-200 dark:border-zinc-500 rounded-lg text-brand-800 dark:text-brand-600 p-1 space-x-1"}>
        <button
          className={`${paginationButtonBaseStyle} ${page <= 0 ? 'text-slate-300 dark:text-zinc-500' : ' hover:bg-brand-100 hover:text-brand-600 hover:dark:bg-brand-700 hover:dark:text-brand-200'}`} disabled={page <= 0} onClick={() => setPage(p => p-1)}>
          <FontAwesomeIcon icon={fa.faChevronLeft} />
        </button>
        {pages.map((i) => {
          return <button className={`${paginationButtonBaseStyle} hover:bg-brand-100 hover:dark:bg-brand-700 hover:text-brand-600 hover:dark:text-brand-200 ${page === i ? 'bg-brand-50 dark:bg-brand-700 border border-brand-500 dark:text-brand-200' : ''}`} key={i} onClick={() => setPage(i)}>
            {i+1}
          </button>
        })}
        <button className={`${paginationButtonBaseStyle} ${(page+1) >= pageCount ? 'text-slate-300 dark:text-zinc-500' : 'hover:bg-brand-100 hover:text-brand-600 hover:dark:bg-brand-700 hover:dark:text-brand-200'}`} disabled={(page+1) >= pageCount} onClick={() => setPage(p => p+1)}>
          <FontAwesomeIcon icon={fa.faChevronRight} />
        </button>
      </div>
    </div> : null}
  </div>
}