import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Customer} from "../api/dto";
import {InputErrors} from "../components/form/InputErrors";
import {SectionHeader} from "../components/content/SectionHeader";
import {SectionDivider} from "../components/content/SectionDivider";
import {Toggle} from "../components/form/Toggle";

export const EditRelationModal: FC<{customer: Customer}> = (props) => {
  const {editCustomer} = useApiCall()
  const [name, setName] = useState(props.customer.name)
  const [email, setEmail] = useState(props.customer.email)
  const [url, setUrl] = useState(props.customer.host ?? '')
  const [phone, setPhone] = useState(props.customer.phone)
  const [contactName, setContactName] = useState(props.customer.contact_person)
  const [isCompany, setIsCompany] = useState(props.customer.name !== props.customer.contact_person)
  const [address, setAddress] = useState(props.customer.address)
  const [coc, setCoc] = useState('')
  const [vatId, setVatId] = useState('')

  const [errors, setErrors] = useState<ErrorBag>({})

  const reload = useRefresh()

  const save = useCallback( async () => {
    const deployment = await editCustomer(props.customer.id, {
      name: name,
      contact_person: contactName,
      email: email,
      phone: phone,
      url: url,
      address: address,
    }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [name, contactName, email, phone, address, url])

  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1 dark:bg-zinc-700"}>
      <Toggle value={!isCompany} onChange={() => setIsCompany(c => !c)} label={isCompany ? 'Zakelijk' : 'Particulier'} />
      {isCompany ? <>
        <SectionHeader>Bedrijfsgegevens</SectionHeader>
        <div className={"items-center space-x-4"}>
          <Input label={"Bedrijfsnaam"} required={true} type={"text"} value={name} placeholder={"Bedrijf BV"}
                 onChange={(v) => setName(v)}/>
          <InputErrors errors={errors} field={'name'}/>
        </div>
        <div className={"items-center space-x-4"}>
          <Input label={"Bedrijfsdomein"} required={false} type={"text"} value={url} placeholder={"bedrijfbv.nl"}
                 onChange={(v) => setUrl(v)}/>
          <InputErrors errors={errors} field={'url'}/>
        </div>
        <div className={"items-center space-x-4"}>
          <Input label={"KvK"} required={false} type={"text"} value={coc} placeholder={"65843289"}
                 onChange={(v) => setCoc(v)}/>
          <InputErrors errors={errors} field={'coc'}/>
        </div>
        <div className={"items-center space-x-4"}>
          <Input label={"BTW"} required={false} type={"text"} value={vatId} placeholder={"NL538472138B01"}
                 onChange={(v) => setVatId(v)}/>
          <InputErrors errors={errors} field={'vat_id'}/>
        </div>
        <div>
          <Input type={"text"} label={"Adres"} value={address} onChange={(v) => setAddress(v)}/>
        </div>
        <InputErrors errors={errors} field={'address'}/>


        <SectionDivider/>
        <SectionHeader>Contactgegevens</SectionHeader>
        <div className={"items-center space-x-4"}>
          <Input label={"Naam"} required={false} type={"text"} value={contactName} placeholder={"Voornaam Achternaam"}
                 onChange={(v) => setContactName(v)}/>
          <InputErrors errors={errors} field={'contact_person'}/>
        </div>
        <div className={"items-center space-x-4"}>
          <Input label={"E-mail"} required={false} type={"text"} value={email} placeholder={"hallo@voorbeeld.nl"}
                 onChange={(v) => setEmail(v)}/>
          <InputErrors errors={errors} field={'email'}/>
        </div>
        <div className={"items-center space-x-4"}>
          <Input label={"Telefoon"} type={"text"} value={phone} placeholder={"+31600000000"}
                 onChange={(v) => setPhone(v)}/>
          <InputErrors errors={errors} field={'phone'}/>
        </div>
      </> : <>
        <SectionHeader>Persoonsgegevens</SectionHeader>
        <div className={"items-center space-x-4"}>
          <Input label={"Naam"} required={true} type={"text"} value={contactName} placeholder={"Voornaam Achternaam"}
                 onChange={(v) => {
                   setContactName(v)
                   setName(v)
                 }}/>
          <InputErrors errors={errors} field={'contact_person'}/>
        </div>
        <div className={"items-center space-x-4"}>
          <Input label={"E-mail"} required={true} type={"text"} value={email} placeholder={"hallo@voorbeeld.nl"}
                 onChange={(v) => setEmail(v)}/>
          <InputErrors errors={errors} field={'email'}/>
        </div>
        <div className={"items-center space-x-4"}>
          <Input label={"Telefoon"} type={"text"} value={phone} placeholder={"+31600000000"}
                 onChange={(v) => setPhone(v)}/>
          <InputErrors errors={errors} field={'phone'}/>
        </div>
        <div>
          <Input type={"text"} label={"Adres"} value={address} onChange={(v) => setAddress(v)}/>
        </div>
        <InputErrors errors={errors} field={'address'}/>
      </>}
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}