import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {InputErrors} from "../components/form/InputErrors";
import {Toggle} from "../components/form/Toggle";
import {TicketTimeCategory} from "../api/dto";

export const EditTicketTimeCategoryModal: FC<{timeCategory: TicketTimeCategory}> = (props) => {
  const [code, setCode] = useState(props.timeCategory.code)
  const [name, setName] = useState(props.timeCategory.name)
  const [isBillable, setIsBillable] = useState(props.timeCategory.isBillable)
  const [errors, setErrors] = useState<ErrorBag>({})

  const {editTicketTimeCategory} = useApiCall()
  const reload = useRefresh()

  const save = useCallback( async () => {
    const deployment = await editTicketTimeCategory(props.timeCategory.id, name, code, isBillable).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [code, name, isBillable])

  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1"}>
      <div className={"space-x-2"}>
        <Input type={"text"} label={"Code"} placeholder={'0001'} value={code} onChange={(n) => {
          setCode(n.split('').filter(x => /\d/.test(x)).join(''));
        }}/>
        <InputErrors errors={errors} field={'code'}/>
      </div>
      <div className={"items-center space-x-4"}>
        <Input label={"Naam"} type={"text"} value={name} onChange={(v) => setName(v)}/>
        <InputErrors errors={errors} field={'priority'}/>
      </div>
      <div className={"items-center space-x-4"}>
        <Toggle label={"Factureerbaar"} value={isBillable} onChange={(v) => setIsBillable(v)}/>
        <InputErrors errors={errors} field={'is_billable'}/>
      </div>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}