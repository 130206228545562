import React, {FC, FormEvent, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {
  faAdd, faBriefcase, faCheck,
  faEnvelope, faExchange, faInbox, faLandmark,
  faLocationPin, faMagnifyingGlass, faPencil,
  faPhone, faSquareArrowUpRight, faTag, faTimes,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import {useFetchedResource} from "../api/APIContext";
import {useApiCall} from "../api/api";
import {useLatestRefreshTime, useRefresh} from "../components/RefreshController";
import {Customer, Tag, User} from "../api/dto";
import {useParams} from "react-router-dom";
import {useTenant} from "../tenant/TenantContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons/faGlobe"
import {Card, CardDivider} from "../components/Card";
import {Button, IconButton} from "../components/form/Button";
import {useModal} from "../components/layout/ModalProvider";
import {EditRelationModal} from "../modals/EditRelationModal";
import {DefaultColors} from "tailwindcss/types/generated/colors";
import {EditTagModal} from "../modals/EditTagModal";
import {DeleteTagModal} from "../modals/DeleteTagModal";
import {Skeleton} from "../components/data/Skeleton";
import {TagPill} from "../components/content/TagPill";
import {IconAvatar} from "../components/content/IconAvatar";
import {UserAvatar} from "../components/content/UserAvatar";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {AddContactModal} from "../modals/AddContactModal";
import {EditUserTagsModal} from "../modals/EditUserTagsModal";
import {EditContactModal} from "../modals/EditContactModal";
import {MoveContactModal} from "../modals/MoveContactModal";
import {Input} from "../components/form/Input";

export const RelationDetails: FC = () => {
  const {getCustomer, getTags} = useApiCall()
  const {tenant} = useTenant()
  const {relation} = useParams()
  const {resource: customerDetails, reload: reloadCustomer} = useFetchedResource((customer: string) => getCustomer(customer))
  const {resource: tags, reload: reloadTags} = useFetchedResource(() => getTags())
  const latestRefreshTime = useLatestRefreshTime()

  useEffect(() => {
    if (relation === undefined) {
      console.warn('relation uuid is undefined')
      return
    }
    reloadTags(undefined)
    reloadCustomer(relation)
  }, [relation, latestRefreshTime])

  return (
    <ContentContainer size={"xl"}>
      <PageIconHeader icon={faUser}>
        <Breadcrumbs crumbs={[
          {label: "CRM", href: `/${tenant}/relations`},
          {label: "Relaties", href: `/${tenant}/relations`},
        ]} currentPage={customerDetails?.name ?? "Details"}/>
        <PageHeader>{customerDetails?.name ?? "Details"}</PageHeader>
      </PageIconHeader>
      <div className={"flex flex-col"}>
        <div className={"flex space-x-8"}>
          {customerDetails ? <div className={"w-1/2"}><Card title={'Bedrijfs Informatie'}>
            <RelationInfo customer={customerDetails}/>
          </Card></div> : <div className={"flex-1"}><Skeleton type={'card'}/></div>}

          {(tags !== null && customerDetails !== null) ? <div className={"flex-1"}><Card title={'Tags'}>
            <RelationTags customer={customerDetails} tags={tags} />
          </Card></div> :  <div className={"flex-1"}><Skeleton type={'card'}/></div>}
        </div>

        {customerDetails ? <div className={"flex-1"}><Card title={'Contactpersonen'}>
          <ContactInfo contactPersons={customerDetails.users} customer={customerDetails} tags={tags ?? []} />
        </Card></div> : <div className={"flex-1"}><Skeleton type={"card"}/></div>}

      </div>
    </ContentContainer>
  )
}

const ContactInfo: FC<{ contactPersons: User[], customer: Customer, tags: Tag[] }> = (props) => {
  const openContactModal = useModal({title: 'Contactpersoon toevoegen', body: <AddContactModal customer={props.customer} />, size: "lg"});
  const [search, setSearch] = useState('')
  const filteredContacts = props.contactPersons.filter(contactPerson => {
    const searchTerms = search.toLowerCase().split(' ')
    return searchTerms.every(term => {
      return contactPerson.first_name?.toLowerCase().includes(term) ||
        contactPerson.middle_name?.toLowerCase().includes(term) ||
        contactPerson.last_name?.toLowerCase().includes(term) ||
        contactPerson.position?.toLowerCase().includes(term) ||
        contactPerson.phone?.toLowerCase().includes(term) ||
        contactPerson.email?.toLowerCase().includes(term) ||
        contactPerson.secondary_email?.toLowerCase().includes(term)
    })
  })
  return (<div className="space-y-5">
      <Input type={'text'} label={''} placeholder={'Zoeken...'} value={search} onChange={setSearch} />
      <CardDivider />
      {props.contactPersons.length > 0 ? (
        filteredContacts.map((contactPerson, index) => {
          return <React.Fragment key={index}>
            <ContactPerson contactPerson={contactPerson} customer={props.customer} tags={props.tags} />
            {index < filteredContacts.length - 1 && <CardDivider/>}
          </React.Fragment>
        })) : (<h1>Nog geen contactpersonen.</h1>)}
      <CardDivider />
      <div className="flex space-x-3">
        <Button type="primary" size="md" text="Toevoegen" icon={faPlus} onClick={() => openContactModal.open()}/>
      </div>
    </div>
  );
};

const ContactPerson: FC<{ contactPerson: User, customer: Customer, tags: Tag[] }> = ({contactPerson, customer, tags}) => {
  const moveModal = useModal({title: 'Contact verplaatsen', body: <MoveContactModal customer={customer} contact={contactPerson} />})
  const tagsModal = useModal({title: 'Tags', body: <EditUserTagsModal customer={customer} user={contactPerson} tags={tags} />})
  const editModal = useModal({title: 'Contact bewerken', body: <EditContactModal customer={customer} contact={contactPerson} />, size: 'lg'})
  return <div>
    <div key={contactPerson.id} className={"flex items-center"}>
      <div className="flex-1 grid grid-cols-1 gap-x-4 gap-y-3 md:grid-cols-2 2xl:grid-cols-3">
        <div className="flex mr-5 space-x-3 items-center">
          <UserAvatar user={contactPerson}/>
          {/* Contact persoon naam */}
          <dl>
            <dt className="text-sm font-medium">Naam</dt>
            <dd
              className="text-brand-900 dark:text-brand-200">{(contactPerson.first_name || contactPerson.middle_name || contactPerson.last_name) ? <>{contactPerson.first_name && `${contactPerson.first_name} `}{contactPerson.middle_name && `${contactPerson.middle_name} `}{contactPerson.last_name && contactPerson.last_name}</> : '-'}</dd>
          </dl>
        </div>
        {/* Contact persoon functie */}
        {contactPerson.position && (
          <div className="flex mr-5 space-x-3 items-center">
            <IconAvatar icon={faBriefcase}/>
            <dl>
              <dt className="text-sm font-medium">Functie</dt>
              <dd className="text-brand-900 dark:text-brand-200">{contactPerson.position}</dd>
            </dl>
          </div>
        )}
        {/* Contact persoon telefoon */}
        {contactPerson.phone && (
          <div className="flex mr-5 space-x-3 items-center">
            <IconAvatar icon={faPhone}/>
            <dl>
              <dt className="text-sm font-medium">Tel.</dt>
              <dd className="text-brand-900 dark:text-brand-200">{contactPerson.phone}</dd>
            </dl>
          </div>
        )}
        {/* Contact persoon email */}
        {contactPerson.email && (
          <div className="flex mr-5 space-x-3 items-center">
            <IconAvatar icon={faEnvelope}/>
            <dl>
              <dt className="text-sm font-medium">Email</dt>
              <dd className="text-brand-900 dark:text-brand-200">{contactPerson.email}</dd>
            </dl>
          </div>
        )}
        {/* Contact persoon secondary email */}
        {contactPerson.secondary_email && (
          <div className="flex mr-5 space-x-3 items-center">
            <IconAvatar icon={faEnvelope}/>
            <dl>
              <dt className="text-sm font-medium">Email (2de)</dt>
              <dd className="text-brand-900 dark:text-brand-200">{contactPerson.secondary_email}</dd>
            </dl>
          </div>
        )}
      </div>
      {/* Actions*/}
      <div className={"flex items-center space-x-4"}>
        <button className={"text-sm h-8 w-8 border border-slate-200 dark:border-zinc-500 rounded-full hover:bg-slate-100 dark:hover:bg-zinc-600 cursor-pointer text-slate-600 dark:text-slate-300"} onClick={() => moveModal.open()}><FontAwesomeIcon icon={faExchange} /></button>
        <button className={"text-sm h-8 w-8 border border-slate-200 dark:border-zinc-500 rounded-full hover:bg-slate-100 dark:hover:bg-zinc-600 cursor-pointer text-slate-600 dark:text-slate-300"} onClick={() => tagsModal.open()}><FontAwesomeIcon icon={faTag} /></button>
        <button className={"text-sm h-8 w-8 border border-slate-200 dark:border-zinc-500 rounded-full hover:bg-slate-100 dark:hover:bg-zinc-600 cursor-pointer text-slate-600 dark:text-slate-300"} onClick={() => editModal.open()}><FontAwesomeIcon icon={faPencil} /></button>
      </div>
    </div>
    <div className={'flex flex-wrap '}>
      {contactPerson.tag_users.map((tagUser, index) => {
        if (!tagUser.tag) return <Fragment key={index}></Fragment>
        return <div className={`mt-3 mr-3`} key={index}><TagPill size={'sm'} tag={tagUser.tag} /></div>
      })}
    </div>
  </div>
}

const RelationInfo: FC<{ customer: Customer }> = (props) => {
  const editRelationModal = useModal({title: 'Relatie bewerken', body: <EditRelationModal customer={props.customer}/>})
  return <div>
    <div className={"space-y-4"}>
      {/* Relation address */}
      {props.customer.address &&
        <div className={"flex space-x-3 items-center"}>
          <IconAvatar icon={faLocationPin}/>
          <dl className={"flex-1"}>
            <dt className={"text-sm font-medium"}>Adres</dt>
            <dd className={"text-brand-900 dark:text-brand-200"}>{props.customer.address}</dd>
          </dl>
          <IconButton icon={faSquareArrowUpRight} size={'sm'} type={'secondary'} onClick={() => {
            window.open(`https://www.google.com/maps/search/?api=1&query=${props.customer.address}`)
          }}/>
        </div>
      }
      {/* Relation email */}
      {props.customer.email &&
        <div className={"flex space-x-3 items-center"}>
          <IconAvatar icon={faEnvelope}/>
          <dl className={"flex-1"}>
            <dt className={"text-sm font-medium"}>E-mail</dt>
            <dd className={"text-brand-900 dark:text-brand-200"}>{props.customer.email}</dd>
          </dl>
          {/*<IconButton icon={faSquareArrowUpRight} size={'sm'} type={'secondary'} onClick={() => {*/}
          {/*  window.open(`mailto:${props.customer.email}`)*/}
          {/*}}/>*/}
        </div>
      }
      {/* Relation site */}
      {props.customer.host &&
        <div className={"flex space-x-3 items-center"}>
          <IconAvatar icon={faGlobe}/>
          <dl className={"flex-1"}>
            <dt className={"text-sm font-medium"}>Domein</dt>
            <dd className={"text-brand-900 dark:text-brand-200"}>{props.customer.host}</dd>
          </dl>
          <IconButton icon={faSquareArrowUpRight} size={'sm'} type={'secondary'} onClick={() => {
            window.open(`https://${props.customer.host}`)
          }}/>
        </div>
      }
      {/* Relation phone */}
      {props.customer.phone &&
        <div className={"flex space-x-3 items-center"}>
          <IconAvatar icon={faPhone}/>
          <dl className={"flex-1"}>
            <dt className={"text-sm font-medium"}>Telefoonnummer</dt>
            <dd className={"text-brand-900 dark:text-brand-200"}>{props.customer.phone}</dd>
          </dl>
          <IconButton icon={faPhone} size={'sm'} type={'secondary'} onClick={() => {
            window.open(`tel:${props.customer.phone}`)
          }}/>
        </div>
      }
      {/* Relation coc */}
      {props.customer.coc &&
        <div className={"flex space-x-3 items-center"}>
          <IconAvatar icon={faBriefcase}/>
          <dl className={"flex-1"}>
            <dt className={"text-sm font-medium"}>KvK nummer</dt>
            <dd className={"text-brand-900 dark:text-brand-200"}>{props.customer.coc}</dd>
          </dl>
        </div>
      }
      {/* Relation vat id */}
      {props.customer.vat_id &&
        <div className={"flex space-x-3 items-center"}>
          <IconAvatar icon={faLandmark}/>
          <dl className={"flex-1"}>
            <dt className={"text-sm font-medium"}>BTW</dt>
            <dd className={"text-brand-900 dark:text-brand-200"}>{props.customer.vat_id}</dd>
          </dl>
        </div>
      }
    </div>
    <CardDivider />
    <div className={'flex space-x-3'}>
    <Button type={'primary'} size={'md'} text={'Bewerken'} icon={faPencil} onClick={() => editRelationModal.open()} />
    {/*<Button type={'danger'} size={'md'} text={'Archiveren'} icon={faArchive} />*/}
    </div>
  </div>
}

const RelationTags: FC<{ customer: Customer, tags: Tag[] }> = (props) => {
  const {addTag} = useApiCall()
  const refresh = useRefresh()
  const [input, setInput] = useState('')
  const [saving, setSaving] = useState(false)
  const add = async (e: FormEvent) => {
    e.preventDefault()
    if (input.length < 2) return
    setSaving(true)
    await addTag(input, getRandomColor())
    refresh()
    setSaving(false)
    setInput('')
  }
  // const customerTagIds = props.customer.tag_customers
  const orderedTags = props.tags
    .filter(tag => tag.name.toLowerCase().includes(input.toLowerCase()))
    .sort((a, b) => {
      const aChecked = props.customer.tags.some(t => t.tag?.id === a.id)
      const bChecked = props.customer.tags.some(t => t.tag?.id === b.id)
      if (aChecked && !bChecked) return -1
      if (!aChecked && bChecked) return 1
      return a.name.localeCompare(b.name);
    })
  return <div>
    <form className={"flex space-x-3"} onSubmit={add}>
      <input type="text" placeholder={'Typ om te zoeken of toevoegen...'} disabled={saving} className={"flex-1 border border-brand-200 dark:border-brand-600 dark:bg-zinc-700 rounded-md p-2"} value={input} onChange={(e) => setInput(e.target.value)}/>
      <IconButton type={'primary'} disabled={input.length < 2 || saving} submit size={'md'} icon={faAdd} />
    </form>
    <div className={'max-h-96 overflow-y-scroll'}>
      {orderedTags.map((tag, i) => {
        return <CheckEditorRow tag={tag} customer={props.customer} key={i} />
      })}
    </div>
    {orderedTags.length === 0 && <>
      {props.tags.length === 0 ? <>
        <div className={"py-8 flex flex-col items-center justify-center text-slate-500 dark:text-slate-300"}>
          <FontAwesomeIcon icon={faInbox} className={"text-lg"}/>
          <span className={'font-medium text-lg mt-3'}>Geen tags</span>
          {input.length < 2 ? <p>Start door een tag toe te voegen</p> : <p>Druk op Enter om <strong>{input}</strong> toe te voegen</p>}
        </div>
      </> : <>
        <div className={"py-8 flex flex-col items-center justify-center text-slate-500 dark:text-slate-300"}>
          <FontAwesomeIcon icon={faMagnifyingGlass} className={"text-lg"}/>
          <span className={'font-medium text-lg mt-3'}>Geen resultaten gevonden</span>
          {input.length < 2 ? <p>Geen tags gevonden</p> : <p>Druk op Enter om <strong>{input}</strong> toe te voegen</p>}
        </div>
      </>}
    </>}
  </div>
}
const CheckEditorRow: FC<{ tag: Tag, customer: Customer }> = (props) => {
  const {addCustomerTag, deleteCustomerTag} = useApiCall()
  const reload = useRefresh()
  const [checked, setChecked] = useState(props.customer.tags.some(t => t.tag?.id === props.tag.id))
  const customerTag = useMemo(() => {
    return props.customer.tags.find(t => t.tag?.id === props.tag.id);
  }, [props.tag.id, props.customer])
  const toggleTag = useCallback(async () => {
    setChecked(!checked)
    if (checked) {
      await deleteCustomerTag(props.customer, props.tag, customerTag?.id ?? 'huh?')
    } else {
      await addCustomerTag(props.customer, props.tag)
    }
    reload()
  }, [customerTag, checked, props.customer, props.tag])
  useEffect(() => {
    setChecked(props.customer.tags.some(t => t.tag?.id === props.tag.id))
  }, [props.customer.tags]);

  const editTagModal = useModal({title: 'Tag bewerken', body: <EditTagModal tag={props.tag}/>})
  const deleteTagModal = useModal({title: 'Tag verwijderen', body: <DeleteTagModal tag={props.tag}/>})

  return <div
    className={"rounded border border-slate-200 dark:border-zinc-600 hover:bg-slate-50 dark:hover:bg-zinc-600 group cursor-pointer h-10 px-3 flex items-stretch mt-3"}
  >
    <div className={"flex items-center flex-1"} onClick={() => toggleTag()}>
      <div className={`h-6 w-6 rounded-full border border-slate-200 mr-3 flex items-center justify-center ${checked ? 'bg-brand-800 border-brand-800 dark:bg-brand-500 dark:border-brand-500' : 'border-slate-200 dark:border-slate-500'}`}>
        <FontAwesomeIcon icon={faCheck} className={checked ? 'text-white dark:text-zinc-950' : "text-transparent group-hover:text-slate-300 dark:group-hover:text-slate-500"}/>
      </div>
      <div className={`flex-1`}>
        <TagPill tag={props.tag} />
      </div>
    </div>
    <div className={"flex items-center"}>
      <div
        onClick={() => editTagModal.open()}
        className={"h-6 w-6 rounded-full hover:bg-brand-200 dark:hover:bg-brand-700 ml-1 flex items-center justify-center text-slate-600 dark:text-slate-400 hover:text-brand-600 dark:hover:text-brand-50"}>
        <FontAwesomeIcon icon={faPencil} className={"h-3"}/>
      </div>
      <div
        onClick={() => deleteTagModal.open()}
        className={"h-6 w-6 rounded-full hover:bg-red-200 dark:hover:bg-red-700 ml-1 flex items-center justify-center text-slate-600 dark:text-slate-400 hover:text-red-600 dark:hover:text-red-50"}>
        <FontAwesomeIcon icon={faTimes} className={"h-3"}/>
      </div>
    </div>
  </div>
}

export const getRandomColor = (): keyof DefaultColors => {
  const colors = [
    'red',
    'orange',
    'amber',
    'yellow',
    'lime',
    'green',
    'emerald',
    'teal',
    'cyan',
    'sky',
    'blue',
    'indigo',
    'violet',
    'purple',
    'fuchsia',
    'pink',
    'rose',
  ]
  return colors[Math.floor(Math.random() * colors.length)] as keyof DefaultColors
}
