import React, {FC, useCallback, useState} from "react";
import {PageIconHeader} from "../../components/layout/PageIconHeader";
import {
  faCircleNotch,
  faExclamationCircle,
  faShop,
  faTimes,
  faWarning
} from "@fortawesome/free-solid-svg-icons";
import {Breadcrumbs} from "../../components/content/Breadcrumbs";
import {PageHeader} from "../../components/content/PageHeader";
import {ContentContainer} from "../../components/content/ContentContainer";
import {useFetchedResource} from "../../api/APIContext";
import {useRefresh, useRefreshEffect} from "../../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../../api/api";
import {CMList, CMListTag, Integration, Tag} from "../../api/dto";
import {Input} from "../../components/form/Input";
import {Button} from "../../components/form/Button";
import {useTenant} from "../../tenant/TenantContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TagPill} from "../../components/content/TagPill";
import {useModal} from "../../components/layout/ModalProvider";
import {AddCMListTagModal} from "../../modals/AddCMListTagModal";
import {CMListSubscribers} from "../../modals/CMListSubscribers";
import {TabContainer} from "../../components/layout/TabContainer";
import {SectionHeader} from "../../components/content/SectionHeader";
import {Paragraph} from "../../components/Paragraph";
import {Card} from "../../components/Card";
import {Callout} from "../../components/content/Callout";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import {Skeleton} from "../../components/data/Skeleton";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {DeleteCMListTagModal} from "../../modals/DeleteCMListTagModal";
import {InputErrors} from "../../components/form/InputErrors";

export const CampaignMonitor: FC = () => {
  const { getIntegrations, getTags } = useApiCall();
  const {tenant} = useTenant()
  const { resource: integrationResource, loading: loadIntegrations, reload: reloadIntegrations } = useFetchedResource(getIntegrations);
  const { resource: tagResource, reload: reloadTags } = useFetchedResource(getTags);

  useRefreshEffect(() => {
    reloadIntegrations(undefined);
    reloadTags(undefined)
  });
  if (loadIntegrations) {
    return <ContentContainer size={"md"}>
      <PageIconHeader icon={faShop} backButton={true}>
        <Breadcrumbs crumbs={[
          {label: "Marketplace", href: `/${tenant}/marketplace`}
        ]} currentPage={"Campaign Monitor"} />
        <PageHeader>Campaign Monitor</PageHeader>
      </PageIconHeader>
      <Skeleton type={'card'}/>
    </ContentContainer>
  }
  if (!integrationResource) {
    return <Callout color={'danger'} icon={faExclamationCircle}>Er is iets verkeerd gegaan.</Callout>;
  }
  const integration = integrationResource.find(item => item.integration === 'campaign_monitor');
  return (
    <ContentContainer size={"md"}>
      <PageIconHeader icon={faShop} backButton={true}>
        <Breadcrumbs crumbs={[
          {label: "Marketplace", href: `/${tenant}/marketplace`}
        ]} currentPage={"Campaign Monitor"} />
        <PageHeader>{integration ? integration.name : ''}</PageHeader>
      </PageIconHeader>

      {integration && tagResource && <IntegrationContent integration={integration} allTags={tagResource} />}
    </ContentContainer>
  )
}

const IntegrationContent: FC<{integration: Integration, allTags: Tag[]}> = (props) => {
  return <TabContainer
    tabs={[
      ...(props.integration.configured ? [
        {name: 'Lijsten & Tags', body: <CampaignMonitorLists allTags={props.allTags} />}
      ] : []),
      {name: 'Details & Informatie', body: <IntegrationDetails integration={props.integration} />},
      {name: 'Instellingen', body: <IntegrationConfig integration={props.integration} />},
    ]}
  />
}

const IntegrationDetails: FC<{ integration: Integration }> = () => {
  return <>
    <div className={"mb-8"}>
      <SectionHeader>Omschrijving</SectionHeader>
      <Paragraph>Campaign Monitor is een geavanceerd e-mailmarketingplatform ontworpen om bedrijven te helpen met het opzetten, beheren en optimaliseren van hun e-mailmarketingcampagnes. Het biedt een breed scala aan tools en functionaliteiten die specifiek gericht zijn op het creëren van aantrekkelijke e-mails, het segmenteren van doelgroepen en het analyseren van de prestaties van campagnes.</Paragraph>
    </div>
  </>
}

const IntegrationConfig: FC<{ integration: Integration }> = (props) => {
  const {postIntegration} = useApiCall()
  const refresh = useRefresh()
  const [options, setOptions] = useState(props.integration.required_options);
  const [errors, setErrors] = useState<ErrorBag>({});

  const handleInputChange = (index: number, newValue: string) => {
    const newOptions = [...options];
    newOptions[index].value = newValue;
    setOptions(newOptions);
  };

  const save = useCallback(async () => {
    await postIntegration(props.integration.integration, options).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    refresh()
  }, [options]);

  const getType = (type: string): 'text'|'password' => {
    switch (type) {
      case 'string':
        return 'text';
      case 'encrypted_string':
        return 'password';
      default:
        return 'text';
    }
  };

  return (
    <>
      {!props.integration.configured && <>
        <Callout color={'warning'} icon={faWarning}>
          Deze integratie is nog niet ingesteld. Stel hem hieronder in
        </Callout>
      </>}

      {props.integration.configured && <>
        <Callout color={'success'} icon={faCircleCheck}>
          Verbinding tussen Windesk en Campaign monitor is actief
        </Callout>
      </>}

      <Card title={props.integration.configured ? "Integratie opnieuw instellen" : 'Integratie setup'}>
        <form className={'flex flex-col'}>
          <InputErrors errors={errors} field={'message'} />
          {options.map((option, i) => (
            <div className={"mt-2 mb-4 flex items-center"} key={i}>
              <Input type={getType(option.type)} label={option.key} value={option.value ?? ''} onChange={(e: string) => handleInputChange(i, e)}/>
              <InputErrors errors={errors} field={option.key} />
            </div>
          ))}
        </form>
        <Button type={'primary'} size={'md'} text={'Opslaan'} onClick={save} />
      </Card>
    </>
  );
};


const CampaignMonitorLists: FC<{allTags: Tag[]}> = (props) => {
  const { getCampaignMonitorLists } = useApiCall();
  const { resource: cmLists, loading: isLoadingCmLists, reload: reloadCmLists } = useFetchedResource(getCampaignMonitorLists);

  useRefreshEffect(() => {
    reloadCmLists(undefined)
  });

  return (
    <div className="flex flex-col space-y-6">
      {isLoadingCmLists && <Skeleton type={'card'}/>}
      {cmLists?.map((cmList, i) => {
        return <CampaignMonitorListCard key={i} list={cmList} tags={props.allTags} />
      })}
    </div>
  );
};

const CampaignMonitorListCard: FC<{list: CMList, tags: Tag[]}> = (props) => {
  const {getCampaignMonitorListDetails } = useApiCall();
  const { resource, reload } = useFetchedResource(getCampaignMonitorListDetails);
  useRefreshEffect(() => {
    reload(props.list.id)
  }, [props.list.id])

  const availableTags = props.tags.filter(tag => !resource?.tags.some(listTag => listTag.tag.id === tag.id));

  const subscriberModal = useModal({title: 'Leden', body: <CMListSubscribers list={props.list.id} subscribers={resource?.subscribers ?? []} tenantTags={props.tags} customers={resource?.customers ?? []} />, size: 'xl'});
  const addTagModal = useModal({title: 'Tags', body: <AddCMListTagModal list={props.list.id} tags={availableTags} currentTags={resource?.tags ?? []} />, size: 'md'});


  return <div className={"bg-white dark:bg-zinc-700 text-black dark:text-zinc-300 rounded"}>
    <header className={"py-4 px-6 border-b border-gray-200 dark:border-zinc-500 flex items-center justify-between font-medium"}>
      <span>{props.list.name}</span>
      {resource ? <button onClick={() => {
        subscriberModal.open()
      }} className={"text-sm text-brand-600 dark:text-brand-300 px-2 py-1 -mr-2 rounded cursor-pointer hover:bg-slate-50 dark:hover:bg-zinc-600"}>{resource.subscribers.length} leden</button> : <FontAwesomeIcon icon={faCircleNotch} className={"text-slate-500 dark:text-zinc-300 text-sm"} spin={true} />}
    </header>
    <div className={"py-4 px-6"}>
      {resource ? <>
        <strong className={"mb-2 block"}>Tags</strong>
        <div className={"flex flex-wrap"}>
          {resource.tags.map((tagItem, j) => {
            return <ManageTags tagItem={tagItem} key={j} />
          })}
          {availableTags.length > 0 && <button onClick={() => {
            addTagModal.open()
          }} className={'px-1 mr-3 mb-2 text-sm text-brand-800 dark:text-brand-100 font-medium'}><FontAwesomeIcon
            icon={faPlus} className={'mr-2'}/>Toevoegen</button>}
        </div>
      </> : <div className={'h-24 flex items-center justify-center'}><FontAwesomeIcon icon={faCircleNotch} spin={true} /></div>}
    </div>
  </div>
}
const ManageTags: FC<{tagItem: CMListTag}> = (props) => {
  const deleteTagModal = useModal({title: 'Verwijder tag', body: <DeleteCMListTagModal tag={props.tagItem} />, size: 'md'});
  return <div className={"relative mr-3 mb-2"}>
    <TagPill tag={props.tagItem.tag}/>
    <button
      onClick={() => {deleteTagModal.open()}}
      className={'absolute inset-0 border rounded-full flex items-center justify-center cursor-pointer bg-transparent hover:bg-[#fffd] dark:hover:bg-[#3f3f46d0] text-transparent hover:text-red-600 dark:hover:text-red-400 border-transparent hover:border-red-600 dark:hover:border-red-400 font-bold'}>
      <FontAwesomeIcon icon={faTimes}/>
    </button>
  </div>
}