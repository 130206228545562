import React, {FC, useState} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ButtonProps {
  type: "primary"|"secondary"|"danger"
  size: "xs"|"sm"|"md"|"lg"|"xl"|"2xl"
  text: string
  icon?: fa.IconDefinition
  submit?: true
  disabled?: boolean
  onClick?: () => void|Promise<void>
  notif?: number
}

export const Button: FC<ButtonProps> = props => {
  const height: string = {
    "xs": "h-6",
    "sm": "h-8",
    "md": "h-10",
    "lg": "h-16",
    "xl": "h-20",
    "2xl": "h-24",
  }[props.size]
  const textStyle: string = {
    "xs": "px-2 text-xs font-medium",
    "sm": "px-2 text-sm font-medium",
    "md": "px-3 text-base font-medium",
    "lg": "px-3 text-lg",
    "xl": "px-4 text-xl",
    "2xl": "px-4 text-xl",
  }[props.size]
  const iconStyle: string = {
    "xs": "mr-2",
    "sm": "mr-1 w-4",
    "md": "mr-3 w-4",
    "lg": "mr-3 w-4",
    "xl": "mr-3 w-4",
    "2xl": "mr-3 w-4",
  }[props.size]
  const type: string = {
    "primary": "bg-brand-900 dark:bg-brand-400 hover:bg-brand-800 dark:hover:bg-brand-500 text-white dark:text-brand-900",
    "secondary": "bg-transparent hover:bg-brand-100 hover:dark:bg-brand-700 text-brand-600 dark:text-brand-400 hover:text-brand-700 hover:dark:text-brand-200",
    "danger": "bg-transparent hover:bg-red-100 hover:dark:bg-red-700 text-red-600 hover:text-red-700 hover:dark:text-red-200",
  }[props.type]
  const disabledStyle: string = type === 'primary' ? "bg-brand-500 text-white cursor-not-allowed" : 'bg-transparent text-gray-400 dark:text-zinc-200 cursor-not-allowed'
  const [isSubmitting, setIsSubmitting] = useState(false)
  const onClick = async () => {
    if (props.disabled || isSubmitting || !props.onClick) return
    const result = props.onClick()
    if (result instanceof Promise) {
      setIsSubmitting(true)
      await result
      setIsSubmitting(false)
    }
  }
  return <button type={props.submit ? "submit" : "button"}
                 onClick={onClick}
                 disabled={props.disabled || isSubmitting}
                 className={`${height} ${(props.disabled||isSubmitting) ? disabledStyle : type} ${textStyle} relative rounded flex items-center justify-center`}
  >
    {(props.icon || isSubmitting) && <FontAwesomeIcon icon={isSubmitting ? fa.faCircleNotch : props.icon!} spin={isSubmitting} className={iconStyle} />}{props.text}
    {props.notif ? <span
      className="absolute bg-red-600 text-red-100 h-5 min-w-5 px-1 flex items-center justify-center text-xs font-bold rounded-full -top-3 -right-2">{props.notif}</span> : <></>}
  </button>
}
export const IconButton: FC<Pick<ButtonProps, 'type'|'disabled'|'size'|'icon'|'submit'|'onClick'|'notif'> & {icon: fa.IconDefinition}> = props => {
  const height: string = {
    "xs": "h-8 w-8",
    "sm": "h-8 w-8",
    "md": "h-10 w-10",
    "lg": "h-16 w-16",
    "xl": "h-20 w-20",
    "2xl": "h-24 w-24",
  }[props.size]
  const type: string = {
    "primary": "bg-brand-900 hover:bg-brand-800 text-white",
    "secondary": "bg-transparent hover:bg-brand-100 hover:dark:bg-zinc-600 text-brand-600 hover:text-brand-700",
    "danger": "bg-transparent hover:bg-red-100 hover:dark:bg-zinc-600 text-red-600 hover:text-red-700",
  }[props.type]
  const disabledStyle: string = type === 'primary' ? "bg-brand-500 text-white cursor-not-allowed" : 'bg-transparent text-gray-400 cursor-not-allowed'
  const [isSubmitting, setIsSubmitting] = useState(false)
  const onClick = async () => {
    if (props.disabled || isSubmitting || !props.onClick) return
    const result = props.onClick()
    if (result instanceof Promise) {
      setIsSubmitting(true)
      await result
      setIsSubmitting(false)
    }
  }
  return <button onClick={onClick} type={props.submit ? "submit" : "button"}
                 className={`${height} ${(props.disabled || isSubmitting) ? disabledStyle : type} relative font-medium rounded flex justify-center items-center`}>
    <FontAwesomeIcon icon={isSubmitting ? fa.faCircleNotch : props.icon} spin={isSubmitting}/>
    {props.notif ? <span
      className="absolute bg-red-600 text-red-100 h-5 min-w-5 px-1 flex items-center justify-center text-xs font-bold rounded-full -top-3 -right-2">{props.notif}</span> : <></>}
  </button>
}