import React, {FC, MouseEvent, Key, useEffect, useMemo, useRef, ReactNode} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";

export type KeyProperties<T> = {
  [P in keyof T]: T[P] extends Key ? P : never;
}[keyof T]

interface AutocompleteProps {
  label: string
  options: {[key: string]: string}
  value: string
  onChange: (newValue: string) => void
}

export const Autocomplete: FC<AutocompleteProps> = (props) =>  {
  const [search, setSearch] = React.useState("")
  const [hasFocus, setHasFocus] = React.useState(false)

  const input = useRef<HTMLInputElement>(null)

  const selectedOptionText = useMemo(() => {
    return props.options[props.value]
  }, [props.options, props.value])

  const options = useMemo(() => Object.entries(props.options).filter(([, value]) => {
    return value.toLowerCase().includes(search.toLowerCase())
  }), [props.options, search])

  const selectOption = (key: string) => {
    setSearch(props.options[key])
    props.onChange(key)
    setHasFocus(false)
    input.current?.blur()
  }

  const [selectedPosition, setSelectedPosition] = React.useState<number>(0)

  useEffect(() => {
    if (! options[selectedPosition]) {
      setSelectedPosition(0)
    }
  }, [selectedPosition, options])

  const onItemClick = (event: MouseEvent<HTMLButtonElement>, key: string) => {
    event.preventDefault()
    selectOption(key)
  }
  const onInputFocus = () => {
    setHasFocus(true)
    setSearch("")
  }
  const onInputBlur = () => {
    setHasFocus(false)
  }

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault()
      if (selectedPosition < options.length - 1) {
        setSelectedPosition(selectedPosition + 1)
      }
    }
    if (event.key === "ArrowUp") {
      event.preventDefault()
      if (selectedPosition > 0) {
        setSelectedPosition(selectedPosition - 1)
      }
    }
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault()
      selectOption(options[selectedPosition][0])
    }
  }

  return <label className={"flex flex-col text-sm font-medium text-slate-900 dark:text-zinc-300 w-full max-w-md"}>
    {props.label}
    <div className={"relative mt-1"}>
      <input
        ref={input}
        onFocus={() => onInputFocus()}
        onBlur={() => onInputBlur()}
        onKeyDown={(e) => onInputKeyDown(e)}
        className={"border-2 border-slate-200 dark:border-zinc-500 outline-slate-700 dark:outline-zinc-800 text-black dark:text-zinc-300 dark:bg-zinc-600 rounded text-base font-normal px-2 h-10 w-full"}
        onChange={(e) => setSearch(e.target.value)}
        value={hasFocus ? search : selectedOptionText}
      />

      {/* Chevron icon*/}
      <div className={"absolute top-0 right-2 h-full flex items-center justify-end"}>
        <FontAwesomeIcon icon={fa.faChevronDown} className={"text-xs text-slate-900 dark:text-zinc-300"} />
      </div>

      {hasFocus && <div className={"absolute top-10 left-0 w-full text-black dark:text-zinc-300 bg-white dark:bg-zinc-600 rounded shadow z-10 max-h-[220px] overflow-y-scroll"}>
        <div className={"flex flex-col items-stretch"}>
          {options.map(([key, value], index) => {
            return <button key={index} className={`h-10 w-full px-4 ${index === selectedPosition ? "bg-slate-100 dark:bg-zinc-500" : ""} hover:bg-slate-100 hover:dark:bg-zinc-500 ${key === props.value ? "text-slate-800 dark:text-white" : ''} text-left`} onMouseDown={(e) => onItemClick(e, key)}>
              {value}
            </button>
          })}
        </div>
      </div>}
    </div>
  </label>
}

interface ObjectAutocompleteProps<T> {
  label: string
  options: T[]
  value: T|null
  onChange: (newValue: T) => void
  displayItem: (obj: T) => string
  idProperty: KeyProperties<T>
}
export const ObjectAutocomplete = function<T>(props: ObjectAutocompleteProps<T>): JSX.Element {
  const getDisplayValue = (option: T) => {
    return props.displayItem(option)
  }
  const getKey = (option: T) => {
    return option[props.idProperty]
  }

  const options = useMemo(() => {
    return props.options.reduce<AutocompleteProps["options"]>((map, option) => {
      const key = getKey(option)
      // @ts-ignore
      map[key] = getDisplayValue(option)
      return map
    }, {})
  }, [props.options])

  const onChange = (newKey: string) => {
    const value = props.options.find(option => getKey(option) === newKey)
    if (value) {
      props.onChange(value)
    }
  }
  if (! props.value) {
    return <AutocompletePlaceholder label={props.label} />
  }
  return <Autocomplete label={props.label} options={options} value={getKey(props.value) as string} onChange={onChange} />
}

const AutocompletePlaceholder: FC<{label: string}> = (props) => {
  return <div className={"flex flex-col text-sm font-medium text-slate-900 w-full max-w-md"}>
    {props.label}
    <div className={"relative mt-1"}>
      <div className={"border-2 border-slate-200 outline-slate-700 text-black rounded text-base font-normal px-2 h-10 w-full"} />
      {/* Chevron icon*/}
      <div className={"absolute top-0 right-2 h-full flex items-center justify-end"}>
        <FontAwesomeIcon icon={fa.faChevronDown} className={"text-xs text-slate-900"} />
      </div>
    </div>
  </div>
}

interface FancyAutocompleteOption {
  key: string
  text: string
  subText?: string
  leading?: ReactNode
}
interface FancyAutocompleteProps {
  label: string
  options: FancyAutocompleteOption[]
  value: string
  onChange: (newValue: string) => void
}

export const FancyAutocomplete: FC<FancyAutocompleteProps> = (props) =>  {
  const [search, setSearch] = React.useState("")
  const [hasFocus, setHasFocus] = React.useState(false)

  const input = useRef<HTMLInputElement>(null)

  const optionMap = useMemo(() => {
    return Object.fromEntries(props.options.map(option => [option.key, option]))
  }, [props.options])

  const selectedOptionText = useMemo(() => {
    return optionMap[props.value]?.text ?? ''
  }, [optionMap, props.value])

  const options = useMemo(() => props.options.filter((value) => {
    return value.text.toLowerCase().includes(search.toLowerCase()) || value.subText?.toLowerCase().includes(search.toLowerCase())
  }), [props.options, search])

  const selectOption = (key: string) => {
    setSearch(optionMap[key].text)
    props.onChange(key)
    setHasFocus(false)
    input.current?.blur()
  }

  const [selectedPosition, setSelectedPosition] = React.useState<number>(0)

  useEffect(() => {
    if (! options[selectedPosition]) {
      setSelectedPosition(0)
    }
  }, [selectedPosition, options])

  const onItemClick = (event: MouseEvent<HTMLButtonElement>, key: string) => {
    event.preventDefault()
    selectOption(key)
  }
  const onInputFocus = () => {
    setHasFocus(true)
    setSearch("")
  }
  const onInputBlur = () => {
    setHasFocus(false)
  }

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault()
      if (selectedPosition < options.length - 1) {
        setSelectedPosition(selectedPosition + 1)
      }
    }
    if (event.key === "ArrowUp") {
      event.preventDefault()
      if (selectedPosition > 0) {
        setSelectedPosition(selectedPosition - 1)
      }
    }
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault()
      selectOption(options[selectedPosition].key)
    }
  }

  return <label className={"flex flex-col text-sm font-medium text-slate-900 dark:text-zinc-300 w-full max-w-md"}>
    {props.label}
    <div className={"relative mt-1"}>
      <input
        ref={input}
        onFocus={() => onInputFocus()}
        onBlur={() => onInputBlur()}
        onKeyDown={(e) => onInputKeyDown(e)}
        className={"border-2 border-slate-200 dark:border-zinc-500 outline-slate-700 dark:outline-zinc-800 text-black dark:text-zinc-300 dark:bg-zinc-600 rounded text-base font-normal px-2 h-10 w-full"}
        onChange={(e) => setSearch(e.target.value)}
        value={hasFocus ? search : selectedOptionText}
      />

      {/* Chevron icon*/}
      <div className={"absolute top-0 right-2 h-full flex items-center justify-end"}>
        <FontAwesomeIcon icon={fa.faChevronDown} className={"text-xs text-slate-900 dark:text-zinc-300"} />
      </div>

      {hasFocus && <div className={"absolute top-10 left-0 w-full text-black dark:text-zinc-300 bg-white dark:bg-zinc-600 rounded shadow z-10 max-h-[220px] overflow-y-scroll"}>
        <div className={"flex flex-col items-stretch"}>
          {options.map((value, index) => {
            return <button key={index} type={'button'} className={`h-10 w-full px-4 ${index === selectedPosition ? "bg-slate-100 dark:bg-zinc-500" : ""} hover:bg-slate-100 hover:dark:bg-zinc-500 ${value.key === props.value ? "text-slate-800 dark:text-white" : ''} flex items-center`} onMouseDown={(e) => onItemClick(e, value.key)}>
              {value.leading ? <div className={'w-4 mr-2 flex items-center justify-center'}>{value.leading}</div> : null}
              <div className={"flex flex-col items-start"}>
                <div className={"text-left"}>{value.text}</div>
                {value.subText ? <div className={"text-xs -mt-1 text-slate-600 dark:text-zinc-400"}>{value.subText}</div> : null}
              </div>
            </button>
          })}
        </div>
      </div>}
    </div>
  </label>
}