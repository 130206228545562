import React, {FC, useCallback, useEffect, useState} from "react";
import {Customer, Tenant, User} from "../api/dto";
import {Input} from "../components/form/Input";
import {InputErrors} from "../components/form/InputErrors";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {useFetchedResource} from "../api/APIContext";
import {Autocomplete} from "../components/form/Autocomplete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {Paragraph} from "../components/Paragraph";

export const MoveContactModal: FC<{customer: Customer, contact: User}> = (props) => {
  const {getCustomers, moveUserToCustomer} = useApiCall()
  const {resource: customers, loading, reload: loadCustomers} = useFetchedResource(() => getCustomers())

  const refactoredCustomers = (customers ?? []).map((item) => [item.id, item.name])
  const [customer, setCustomer] = useState(props.customer.id)
  const [errors, setErrors] = useState<ErrorBag>({})

  useEffect(() => {
    loadCustomers(undefined)
  }, []);


  const reload = useRefresh()

  const save = useCallback(async () => {
    await moveUserToCustomer(props.contact, props.customer, customer).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [props.contact, props.customer, customer])

  return <>
    <form onSubmit={() => save} className={'space-y-4 flex-1'}>
      {loading ? <div className={"py-8 text-xl text-center"}><FontAwesomeIcon icon={faCircleNotch} spin={true} /></div> : <div className={'min-h-[calc(220px+4rem)] space-y-8'}>
        <Autocomplete label={"Relatie"} options={Object.fromEntries(refactoredCustomers)} value={customer}
                      onChange={(v) => setCustomer(v)}/>
        <InputErrors errors={errors} field={'remark'}/>
        <Paragraph>
          Met het verplaatsen van een contactpersoon behoud deze wel zijn tags en notities, maar komt hij/zij onder een andere relatie te staan.
        </Paragraph>
      </div>}
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}


