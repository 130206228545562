import React, {FC, useState} from "react";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Ticket, TicketTime} from "../api/dto";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {InputErrors} from "../components/form/InputErrors";

export const DeleteTicketTimeModal: FC<{ticket: Ticket, time: TicketTime}> = (props) => {
  const {deleteTicketTime} = useApiCall()
  const reload = useRefresh()
  const [errors, setErrors] = useState<ErrorBag>({})

  const confirm = async () => {
    const deployment = await deleteTicketTime(props.ticket.id, props.time.id).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }

  return <>
    <p><strong>Let op!</strong> Je probeert nu <strong>{props.time.description}</strong> te verwijderen van je gewerkte tijden op dit ticket.</p>
    <p>Wilt u deze actie voortzetten?</p>
    <InputErrors errors={errors} field={'error'} />
      <ModalFooter icon={faTrash} text={"Verwijderen"} onSubmit={confirm}/>
  </>
}