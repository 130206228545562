import {useCallback} from "react";
import {getToken} from "../auth/AuthProvider";
import config from "../config";
import {
  DashboardReport, CMList, CMListDetails, CMListSubscriber, CMListTag,
  Customer, Deal, dealStage, Integration, Sla, SlaRule, Tag, Team,
  Tenant,
  Ticket,
  TicketAttachment,
  TicketNote,
  TicketPriority,
  TicketReply,
  TicketStatus, TicketTask, TicketTime, TicketTimeCategory,
  User
} from "./dto";
import {useTenant} from "../tenant/TenantContext";
import {useNavigate} from "react-router-dom";
import {DefaultColors} from "tailwindcss/types/generated/colors";

const API_BASE_URL = config.apiBaseUrl

export const useApiCall = () => {
  const {tenant} = useTenant()
  const navigate = useNavigate()
  const checkForValidationErrors = async (response: Response): Promise<void> => {
    if (response.status === 403) {
      navigate('/forbidden')
      throw new Error('You do not have permission to perform this action.')
    }
    if (response.status === 422) {
      const json = await response.json()
      if ("message" in json && "errors" in json && typeof json.errors === "object") {
        throw new ValidationError(json.message, json.errors)
      }
      if ("message" in json) {
        throw new ValidationError(json.message, {'message': [json.message]})
      }
      if ("error" in json) {
        throw new ValidationError(json.error, {'message': [json.error]})
      }
    }
  }

  const get = useCallback(async function<T>(url: string) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      }
    })
    await checkForValidationErrors(response)
    return await response.json() as T
  }, [])
  const post = useCallback(async function<T>(url: string, body: object) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(body)
    })
    await checkForValidationErrors(response)
    return await response.json() as T
  }, [])
  const postForm = useCallback(async function<T>(url: string, body: FormData) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      body: body
    })
    await checkForValidationErrors(response)
    return await response.json() as T
  }, [])
  const putForm = useCallback(async function<T>(url: string, body: FormData) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      body: body
    })
    await checkForValidationErrors(response)
    return await response.json() as T
  }, [])
  const put = useCallback(async function<T>(url: string, body: object) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(body)
    })
    await checkForValidationErrors(response)
    return await response.json() as T
  }, [])
  const patch = useCallback(async function<T>(url: string, body: object) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(body)
    })
    await checkForValidationErrors(response)
    return await response.json() as T
  }, [])
  const del = useCallback(async function<T>(url: string) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      }
    })
    await checkForValidationErrors(response)
    return await response.json() as T
  }, [])

  const getTenant = async () => {
    const json = await get<{item: object}>(`tenants/${tenant??'unknown-tenant'}`)
    return Tenant.fromJson(json.item)
  }

  const editTenant = async (name?: string, color_palette?: string) => {
    const json = await post<{ item: object[] }>(`${tenant??'unknown-tenant'}/settings`,
      {
        name: name,
        color_palette: color_palette,
      })
    return json
  }

  const editTenantLogo = async (logo: File | string | undefined) => {
    let json;

    if (logo) {
      if (logo instanceof File) {
        console.log('logo is file')
        const formData = new FormData();
        formData.append('logo', logo, logo.name);

        json = await postForm<{ item: object }>(`${tenant ?? 'unknown-tenant'}/settings`, formData);
      } else {
        json = await post<{ item: object[] }>(`${tenant ?? 'unknown-tenant'}/settings`, {
          logo: logo,
        });
      }
    } else {
      json = await post<{ item: object[] }>(`${tenant ?? 'unknown-tenant'}/settings`, {});
    }

    return json;
  }

  const getTenantLogoURL = (tenantSlug?: string) => {
    return `${API_BASE_URL}/${tenantSlug ?? tenant ?? 'unknown-tenant'}/logo?accessToken=${getToken()}`
  }

  const getTenants = async () => {
    const json = await get<{items: object[]}>(`tenants`)
    return json.items.map(item => Tenant.fromJson(item))
  }

  const getAllTicketsForTenant = useCallback(async () => {
    const json = await get<{items: object[]}>(`${tenant??'unknown-tenant'}/tickets`)
    return json.items.map(item => Ticket.fromJson(item))
  }, [get, tenant])

  const getAllTicketsForTeam = async (team: string) => {
    const json = await get<{items: object[]}>(`${tenant ?? 'unknown-tenant'}/teams/${team}/tickets`)
    return json.items.map(item => Ticket.fromJson(item))
  }

  const addTicket = async (data: object)=> {
    return await post<{ item: object }>(`${tenant??'unknown-tenant'}/tickets`, data)
  }

  const getTicket = async (ticket: string)=> {
    const json = await get<{item: object}>(`${tenant??'unknown-tenant'}/tickets/${ticket}`)
    return Ticket.fromJson(json.item)
  }

  const editTicket = async (ticket: string, data: object) => {
    const json = await patch<{item: object[]}>(`${tenant??'unknown-tenant'}/tickets/${ticket}`, data)
    return json
  }

  const addTicketTime = async (ticket: string, date: Date, value: number, description: string, category: string) => {
    const json = await post<{ item: object[]}>(`${tenant??'unknown-tenant'}/tickets/${ticket}/times`, {
      created_at: date,
      value: value,
      description: description,
      category: category,
    })
    return TicketTime.fromJson(json.item)
  }

  const editTicketTime = async (ticket: string, date: Date, value: number, description: string, category: string) => {
    const json = await patch<{ item: object[]}>(`${tenant??'unknown-tenant'}/tickets/${ticket}/times`, {
      updated_at: date,
      value: value,
      description: description,
      category: category,
    })
    return TicketTime.fromJson(json.item)
  }

  const deleteTicketTime = async (ticket: string, time: string)=> {
    const json = await del<void>(`${tenant??'unknown-tenant'}/tickets/${ticket}/times/${time}`)
  }

  const addTicketTask = async (ticket: string, userId: string, description: string) => {
    const json = await post<{ item: object[]}>(`${tenant??'unknown-tenant'}/tickets/${ticket}/tasks`, {
      user_uuid: userId,
      description: description,
    })
    return TicketTask.fromJson(json.item)
  }

  const checkTicketTask = async (ticket: string, task: string): Promise<void> => {
    await patch<{ item: object }>(`${tenant??'unknown-tenant'}/tickets/${ticket}/tasks/${task}`, {
      is_done: true
    })
  }

  const uncheckTicketTask = async (ticket: string, task: string): Promise<void> => {
    await patch<{ item: object }>(`${tenant??'unknown-tenant'}/tickets/${ticket}/tasks/${task}`, {
      is_done: false
    })
  }

  const deleteTicketTask = async (ticket: string, task: string): Promise<void> => {
    const json = await del<void>(`${tenant??'unknown-tenant'}/tickets/${ticket}/tasks/${task}`)
  }

  const getDashboardReport = async () => {
    const json = await get<{item: object}>(`${tenant??'unknown-tenant'}/dashboard/ticket-times`)
    return DashboardReport.fromJson(json)
  }

  const getReplies = async (ticket: string)=> {
    const json = await get<{items: object[]}>(`${tenant??'unknown-tenant'}/tickets/${ticket}/replies`)
    return json.items.map(item => TicketReply.fromJson(item))
  }

  const addReply = async (ticket: string, data: object) => {
    return await post<{ item: object }>(`${tenant??'unknown-tenant'}/tickets/${ticket}/replies`, data)
  }

  const editReply = async (ticket: string, reply: string, data: object) => {
    const json = await put<{item: object[]}>(`${tenant??'unknown-tenant'}/tickets/${ticket}/replies/${reply}`, data)
    return json
  }

  const deleteReply = async (ticket: string, reply: string)=> {
    const json = await del<void>(`${tenant??'unknown-tenant'}/tickets/${ticket}/replies/${reply}`)
  }

  const getNotes = async (ticket: string) => {
    const json = await get<{items: object[]}>(`${tenant??'unknown-tenant'}/tickets/${ticket}/notes`)
    return json.items.map(item => TicketNote.fromJson(item))
  }

  const addNote = async (ticket: string, data: object) => {
    return await post<{ item: object }>(`${tenant??'unknown-tenant'}/tickets/${ticket}/notes`, data)
  }

  const editNote = async (ticket: string, note: string, data: object) => {
    const json = await put<{item: object[]}>(`${tenant??'unknown-tenant'}/tickets/${ticket}/notes/${note}`, data)
    return json
  }

  const deleteNote = async (ticket: string, note: string)=> {
    const json = await del<void>(`${tenant??'unknown-tenant'}/tickets/${ticket}/notes/${note}`)
  }

  const getAttachments = async (ticket: string) => {
    const json = await get<{items: object[]}>(`${tenant??'unknown-tenant'}/tickets/${ticket}/attachments`)
    return json.items.map(item => TicketAttachment.fromJson(item))
  }

  const getAttachmentContentURL = (ticket: string, attachment: string) => {
    return `${API_BASE_URL}/${tenant??'unknown-tenant'}/tickets/${ticket}/attachments/${attachment}?accessToken=${getToken()}`
  }

  const addAttachments = async (ticket:string, files: File[])=> {
    const formData = new FormData()
    files.forEach((file) => {
      formData.append('files[]', file, file.name)
    })
    return await postForm<{ item: object}>(`${tenant??'unknown-tenant'}/tickets/${ticket}/attachments`, formData);
  }

  const deleteFile = async (ticket: string, attachment: string)=> {
    const json = await del<void>(`${tenant??'unknown-tenant'}/tickets/${ticket}/attachments/${attachment}`);
  }

  const getAllStatuses = async () => {
    const json = await get<{items: object[]}>(`${tenant??'unknown-tenant'}/statuses`)
    return json.items.map(item => TicketStatus.fromJson(item))
  }

  const getTicketStatus = async (ticket: string) => {
    const json = await get<{item: object}>(`${tenant??'unknown-tenant'}/tickets/${ticket}/status`)
    return TicketStatus.fromJson(json.item)
  }

  const addStatus = async (data: object) => {
    const json = await post<{item: object}>(`${tenant??'unknown-tenant'}/statuses`, data)
    return json
  }

  const editStatus = async (status:string, data:object) => {
    const json = await put<{item: object[]}>(`${tenant??'unknown-tenant'}/statuses/${status}`, data)
    return json
  }

  const deleteStatus = async (status: string) => {
    const json = await del<void>(`${tenant??'unknown-tenant'}/statuses/${status}`)
  }

  const getTicketStatusCount = async () => {
    const json = await get<{item: object[]}>(`${tenant??'unknown-tenant'}/tickets/statusCount`)
    return json
  }

  const getAllPriorities = async (): Promise<TicketPriority[]> => {
    const json = await get<{items: object[]}>(`${tenant??'unknown-tenant'}/priorities`)
    return json.items.map(item => TicketPriority.fromJson(item))
  }

  const getTicketPriority = async (ticket: string) => {
    const json = await get<{item: object}>(`${tenant??'unknown-tenant'}/tickets/${ticket}/priority`)
    return TicketPriority.fromJson(json.item)
  }

  const addPriority = async (data: object) => {
    const json = await post<{item: object}>(`${tenant??'unknown-tenant'}/priorities`, data)
    return json
  }

  const editPriority = async (priority:string, data:object) => {
    const json = await put<{item: object[]}>(`${tenant??'unknown-tenant'}/priorities/${priority}`, data)
    return json
  }

  const deletePriority = async (priority: string) => {
    const json = await del<void>(`${tenant??'unknown-tenant'}/priorities/${priority}`)
  }

  const addCustomer = async (data: object) => {
    const json = await post<{item: object}>(`${tenant??'unknown-tenant'}/customers`, data)
    return json
  }
  const editCustomer = async (customer: string, data: object) => {
    const json = await put<{item: object}>(`${tenant??'unknown-tenant'}/customers/${customer}`, data)
    return json
  }

  const getCustomers = async () => {
    const json = await get<{items: object[]}>(`${tenant??'unknown-tenant'}/customers`)
    return json.items.map(item => Customer.fromJson(item))
  }

  const getCustomer = async (customer: string) => {
    const json = await get<{item: object[]}>(`${tenant??'unknown-tenant'}/customers/${customer}`)
    console.log(json)
    return Customer.fromJson(json.item)
  }

  const addCustomerUser = async (customer: string, firstName: string, middleName: string, lastName: string, email: string, secondaryEmail: string, position: string, remark: string, phone: string) => {
    await post<{item: object}>(`${tenant??'unknown-tenant'}/customers/${customer}/users`, {
      'first_name': firstName,
      'middle_name': middleName,
      'last_name': lastName,
      'position': position,
      'email': email,
      'secondary_email': secondaryEmail,
      'address': null,
      'zipcode': null,
      'city': null,
      'remark': remark,
      'phone': phone,
    })
  }

  const editCustomerUser = async (customer: string, user: string, firstName: string, middleName: string, lastName: string, email: string, secondaryEmail: string, position: string, remark: string, phone: string) => {
    await put<{item: object}>(`${tenant??'unknown-tenant'}/customers/${customer}/users/${user}`, {
      'first_name': firstName,
      'middle_name': middleName,
      'last_name': lastName,
      'position': position,
      'email': email,
      'secondary_email': secondaryEmail,
      'address': null,
      'zipcode': null,
      'city': null,
      'remark': remark,
      'phone': phone,
    })
  }

  const getUser = async (user: string) => {
    const json = await get<{item: object}>(`users/${user}`)
    return User.fromJson(json.item)
  }

  const getAllUsersForTenant = async () => {
    const json = await get<{items: object[]}>(`${tenant ?? 'unknown-tenant'}/users`)
    return json.items.map((user) => User.fromJson(user))
  }

  const addTicketLog = async (ticket: string, data: object) => {
    const json = await put<{item: object}>(`${tenant??'unknown-tenant'}/tickets/${ticket}/log`, data)
    return json
  }

  const getAllSlas = async () => {
    const json = await get<{ items: object[] }>(`${tenant ?? 'unknown-tenant'}/slas`)
    return json.items.map(item => Sla.fromJson(item))
  }

  const getSlaById = async (sla: string) => {
    const json = await get<{item: object}>(`${tenant ?? 'unknown-tenant'}/slas/${sla}`)
    return Sla.fromJson(json.item)
  }

  const getSlaForCustomer = async (customer: string) => {
    const json = await get<{items: object[]}>(`${tenant ?? 'unknown-tenant'}/customers/${customer}/sla`)
    return json.items.map(item => Sla.fromJson(item));
  }

  const addSla = async (sla_name: string, rule_items: object) => {
    const json = await post<{item: object}>(`${tenant ?? 'unknown-tenant'}/slas`, {
      sla_name: sla_name,
      rule_items: rule_items
    })
    return json
  }

  const editSla = async (sla: string, sla_name:string, rule_items: object)=> {
    const json = await put<{item: object}>(`${tenant ?? 'unknown-tenant'}/slas/${sla}`, {
      sla_name: sla_name,
      rule_items: rule_items
    })
    return json
  }

  const deleteSla = async (sla: string) => {
    const json = await del<{item: object}>(`${tenant ?? 'unknown-tenant'}/slas/${sla}`)
  }

  const addSlaRule = async (sla: string, ruleType: string, priorityId: string, duringOfficeHours: boolean, value: string) => {
    const json = await post<{item: object}>(`${tenant ?? 'unknown-tenant'}/slas/${sla}/rules`, {
      priority_id: priorityId,
      rule_type: ruleType,
      during_office_hours: duringOfficeHours,
      value: value,
    })
    return json
  }

  const getSlaRules = async (sla: string, rule_type: string, priority: string) => {
    const json = await get<{items: object[]}>(`${tenant ?? 'unknown-tenant'}/slas/${sla}/rules/${rule_type}`)
    return json.items.map((item) => SlaRule.fromJson(item))
  }

  const editSlaRule = async (sla: string, rule: string, slaRuleId: string, newValue: string)=> {
    const json = await put<{item: object}>(`${tenant ?? 'unknown-tenant'}/slas/${sla}/rules/${rule}`, {
      sla_rule_id: slaRuleId,
      value: newValue,
    })
    return json
  }

  const setCustomerSla = async (customer: string, sla: string|undefined)=> {
    const json = await put<{item: object}>(`${tenant ?? 'unknown-tenant'}/customers/${customer}`, {
      sla_uuid: sla,
    })
    return json
  }

  const addTeam = async (teamName: string)=> {
    const json = await post<{item: object}>(`${tenant ?? 'unknown-tenant'}/teams`, {
      team_name: teamName,
    })
    return json
  }

  const getTeams = async () => {
    const json = await get<{items: object[]}>(`${tenant ?? 'unknown-tenant'}/teams`)
    return json.items.map((item) => Team.fromJson(item))
  }

  const getTeam = async (team: string) => {
    const json = await get<{item: object}>(`${tenant ?? 'unknown-tenant'}/teams/${team}`)
    return Team.fromJson(json.item)
  }

  const editTeam = async (team: string, team_name: string)=> {
    const json = await put<{item: object}>(`${tenant ?? 'unknown-tenant'}/teams/${team}`, {
      team_name: team_name,
    })
    return json
  }

  const deleteTeam = async (team: string) => {
    const json = del<{item: object}>(`${tenant ?? 'unknown-tenant'}/teams/${team}`)
    return json
  }

  const addTeamUser = async (team: string, data: object) => {
    const json = await post<{item: object}>(`${tenant??'unknown-tenant'}/teams/${team}/users`, data)
    return json
  }

  const getTags = async (): Promise<Tag[]> => {
    const json = await get<{items: object[]}>(`${tenant ?? 'unknown-tenant'}/tags`)
    return json.items.map((item) => Tag.fromJson(item))
  }
  const addTag = async (name: string, color: keyof DefaultColors): Promise<Tag> => {
    const json = await post<{item: object}>(`${tenant ?? 'unknown-tenant'}/tags`, {
      name,
      color,
    })
    return Tag.fromJson(json.item)
  }
  const editTag = async (tag: string, name: string, color: keyof DefaultColors): Promise<Tag> => {
    const json = await put<{item: object}>(`${tenant ?? 'unknown-tenant'}/tags/${tag}`, {
      name,
      color,
    })
    return Tag.fromJson(json.item)
  }
  const deleteTag = async (tag: string): Promise<void> => {
    await del<{item: object}>(`${tenant ?? 'unknown-tenant'}/tags/${tag}`)
  }
  const addCustomerTag = async (customer: Customer, tag: Tag): Promise<void> => {
    await post<{item: object}>(`${tenant ?? 'unknown-tenant'}/customers/${customer.id}/tags`, {
      tag_id: tag.id,
    })
  }
  const deleteCustomerTag = async (customer: Customer, tag: Tag, customerTag: string): Promise<void> => {
    await del<{item: object}>(`${tenant ?? 'unknown-tenant'}/customers/${customer.id}/tags/${tag.id}/${customerTag}`)
  }
  const addUserTag = async (customer: Customer, user: User, tag: Tag): Promise<void> => {
    await post<{item: object}>(`${tenant ?? 'unknown-tenant'}/customers/${customer.id}/users/${user.id}/tags`, {
      tag_id: tag.id,
    })
  }
  const deleteUserTag = async (customer: Customer, user: User, tag: Tag, userTag: string): Promise<void> => {
    await del<{item: object}>(`${tenant ?? 'unknown-tenant'}/customers/${customer.id}/users/${user.id}/tags/${tag.id}/${userTag}`)
  }
  const moveUserToCustomer = async (user: User, fromCustomer: Customer, toCustomerId: string): Promise<void> => {
    await post<{item: object}>(`${tenant ?? 'unknown-tenant'}/customers/${fromCustomer.id}/users/${user.id}/move`, {
      customer_id: toCustomerId,
    })
  }

  const getDeals = async (): Promise<Deal[]> => {
    const json = await get<{items: object[]}>(`${tenant ?? 'unknown-tenant'}/deals`)
    return json.items.map((item) => Deal.fromJson(item))
  }
  const getDeal = async (dealId: string): Promise<Deal> => {
    const json = await get<{item: object}>(`${tenant ?? 'unknown-tenant'}/deals/${dealId}`)
    return Deal.fromJson(json.item)
  }
  const addDeal = async (customerId: string, name: string, amount: number, purchase_price: number, description: string|null): Promise<Deal> => {
    const json = await post<{item: object}>(`${tenant ?? 'unknown-tenant'}/deals`, {
      name,
      amount: Math.floor(amount * 100),
      purchase_price: Math.floor(purchase_price * 100),
      description,
      customer_id: customerId,
    })
    return Deal.fromJson(json.item)
  }
  const editDeal = async (dealId: string, fields: {
    customerId?: string,
    userId?: string,
    stage?: dealStage,
    name?: string,
    amount?: number,
    purchasePrice?: number,
    description?: string|null
  }): Promise<Deal> => {
    const json = await put<{item: object}>(`${tenant ?? 'unknown-tenant'}/deals/${dealId}`, {
      ...(fields.name !== undefined ? {name: fields.name} : {}),
      ...(fields.amount !== undefined ? {amount: Math.floor(fields.amount * 100)} : {}),
      ...(fields.purchasePrice !== undefined ? {purchase_price: Math.floor(fields.purchasePrice * 100)} : {}),
      ...(fields.description !== undefined ? {description: fields.description} : {}),
      ...(fields.stage !== undefined ? {stage: fields.stage} : {}),
      ...(fields.customerId !== undefined ? {customer_id: fields.customerId} : {}),
      ...(fields.userId !== undefined ? {user_id: fields.userId} : {}),
    })
    return Deal.fromJson(json.item)
  }
  const deleteDeal = async (dealId: string): Promise<void> => {
    await del<{item: object}>(`${tenant ?? 'unknown-tenant'}/deals/${dealId}`)
  }

  const addContact = async (first_name: string, middle_name: string, last_name: string, email: string, secondary_email: string, phone: string, position: string, remark: string, customer_uuid: string): Promise<User> => {
    const json = await post<{ item: object }>(`${tenant ?? 'unknown-tenant'}/users`, {
      first_name,
      middle_name,
      last_name,
      email,
      secondary_email,
      phone,
      position,
      remark,
      customer_uuid,
    })
    return User.fromJson(json.item)
  }

  const getMFAStatus = async () => {
    const json = await get<{item: boolean}>('user/mfa-status')
    return json;
  }

  const enableMFA = async () => {
    await post<{item: object}>('user/mfa-enable', {})
  }

  const getIntegrations = async () => {
    const json = await get<{items: object[]}>(`${tenant ?? 'unknown-tenant'}/marketplace/integrations`)
    return json.items.map((item) => Integration.fromJson(item))
  }

  const postIntegration = async (integration: string, options: Array<{
    key: string,
    type: string,
    value?: string,
  }>) => {
      const json = await post<{ item: object }>(`${tenant ?? 'unknown-tenant'}/marketplace/integrations`, {
        integration,
        ...options.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {})
      });
      return json;
  };

  const getCampaignMonitorLists = async () => {
    const json = await get<{items: object[]}>(`${tenant ?? 'unknown-tenant'}/marketplace/integrations/campaign_monitor/lists`)
    return json.items.map((item) => CMList.fromJson(item))
  }

  const getCampaignMonitorListDetails = async (list: string) => {
    const json = await get<{ item: object }>(`${tenant ?? 'unknown-tenant'}/marketplace/integrations/campaign_monitor/lists/${list}`)
    return CMListDetails.fromJson(json.item);
  }


  const getCampaignMonitorListsTags = async (list: string) => {
    const json = await get<{items: object[]}>(`${tenant ?? 'unknown-tenant'}/marketplace/integrations/campaign_monitor/lists/${list}/tags`)
    return json.items.map((item) => CMListTag.fromJson(item))
  }

  const updateCampaignMonitorListsTags = async (list: string, tag: string) => {
    const json = await post<{items: object[]}>(`${tenant ?? 'unknown-tenant'}/marketplace/integrations/campaign_monitor/lists/${list}/tags`, {
      tag: tag
    })
    return json
  }

  const deleteCampaignMonitorListsTags = async (list: string, tag: string) => {
    const json = await del<{item: object}>(`${tenant ?? 'unknown-tenant'}/marketplace/integrations/campaign_monitor/lists/${list}/tags/${tag}`)
    return json
  }

  const getCampaignMonitorListsSubscribers = async (list: string) => {
    const json = await get<{items: object[]}>(`${tenant ?? 'unknown-tenant'}/marketplace/integrations/campaign_monitor/lists/${list}/subscribers`)
    return json.items.map((item)=> CMListSubscriber.fromJson(item))
  }



  const getTicketTimeCategories = async (): Promise<TicketTimeCategory[]> => {
    const json = await get<{items: object[]}>(`${tenant ?? 'unknown-tenant'}/ticket_time_categories`)
    return json.items.map((item) => TicketTimeCategory.fromJson(item))
  }
  const getTicketTimeCategory = async (ticketTimeCategoryId: string): Promise<TicketTimeCategory> => {
    const json = await get<{item: object}>(`${tenant ?? 'unknown-tenant'}/ticket_time_categories/${ticketTimeCategoryId}`)
    return TicketTimeCategory.fromJson(json.item)
  }
  const addTicketTimeCategory = async (name: string, code: string, isBillable: boolean): Promise<TicketTimeCategory> => {
    const json = await post<{item: object}>(`${tenant ?? 'unknown-tenant'}/ticket_time_categories`, {
      name,
      code,
      is_billable: isBillable,
    })
    return TicketTimeCategory.fromJson(json.item)
  }
  const editTicketTimeCategory = async (ticketTimeCategoryId: string, name: string, code: string, isBillable: boolean): Promise<TicketTimeCategory> => {
    const json = await put<{item: object}>(`${tenant ?? 'unknown-tenant'}/ticket_time_categories/${ticketTimeCategoryId}`, {
      name,
      code,
      is_billable: isBillable,
    })
    return TicketTimeCategory.fromJson(json.item)
  }
  const deleteTicketTimeCategory = async (ticketTimeCategoryId: string): Promise<void> => {
    await del<{item: object}>(`${tenant ?? 'unknown-tenant'}/ticket_time_categories/${ticketTimeCategoryId}`)
  }



  return {
    getTenant,
    editTenant,
    editTenantLogo,
    getTenantLogoURL,
    getTenants,
    getAllTicketsForTenant,
    getAllTicketsForTeam,
    addTicket,
    getTicket,
    editTicket,
    addTicketTime,
    editTicketTime,
    deleteTicketTime,
    addTicketTask,
    checkTicketTask,
    uncheckTicketTask,
    deleteTicketTask,
    getDashboardReport,
    getReplies,
    addReply,
    editReply,
    deleteReply,
    getNotes,
    addNote,
    editNote,
    deleteNote,
    getAttachments,
    addAttachments,
    getAttachmentContentURL,
    deleteFile,
    getAllStatuses,
    getTicketStatus,
    addStatus,
    editStatus,
    deleteStatus,
    getTicketStatusCount,
    getAllPriorities,
    getTicketPriority,
    addPriority,
    editPriority,
    deletePriority,
    addCustomer,
    editCustomer,
    getCustomers,
    getCustomer,
    addCustomerUser,
    editCustomerUser,
    getUser,
    getAllUsersForTenant,
    addTicketLog,
    getAllSlas,
    getSlaById,
    getSlaForCustomer,
    addSla,
    editSla,
    deleteSla,
    addSlaRule,
    getSlaRules,
    editSlaRule,
    setCustomerSla,
    addTeam,
    getTeams,
    getTeam,
    editTeam,
    deleteTeam,
    addTeamUser,
    getTags,
    addTag,
    editTag,
    deleteTag,
    addCustomerTag,
    deleteCustomerTag,
    addUserTag,
    deleteUserTag,
    moveUserToCustomer,
    getDeals,
    getDeal,
    addDeal,
    editDeal,
    deleteDeal,
    addContact,
    getMFAStatus,
    enableMFA,
    getTicketTimeCategories,
    getTicketTimeCategory,
    addTicketTimeCategory,
    editTicketTimeCategory,
    deleteTicketTimeCategory,
    getIntegrations,
    postIntegration,
    getCampaignMonitorLists,
    getCampaignMonitorListDetails,
    getCampaignMonitorListsTags,
    updateCampaignMonitorListsTags,
    deleteCampaignMonitorListsTags,
    getCampaignMonitorListsSubscribers
  }
}

export type ErrorBag = { [key: string]: string[] }
export class ValidationError extends Error {
  constructor(public readonly message: string, public readonly errors: ErrorBag) {
    super(message);
  }
}