import React, {FC, useState} from "react";
import {Ticket, TicketTime, TicketTimeCategory} from "../../../api/dto";
import {faAdd, faChevronDown, faChevronUp, faCircleNotch, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {Button, IconButton} from "../../form/Button";
import {useModal} from "../../layout/ModalProvider";
import {faArrowUpArrowDown} from "@fortawesome/pro-regular-svg-icons";
import {usePersistentState} from "../../../util/usePersistentState";
import {IconDropdownButton} from "../../form/IconButtonDropdown";
import {DeleteTicketTimeModal} from "../../../modals/DeleteTicketTimeModal";
import {EditTicketTimeModal} from "../../../modals/EditTicketTimeModal";
import {NavLink} from "react-router-dom";
import {useTenant} from "../../../tenant/TenantContext";
import {Input} from "../../form/Input";
import {Autocomplete, FancyAutocomplete} from "../../form/Autocomplete";
import {useFetchedResource} from "../../../api/APIContext";
import {ErrorBag, useApiCall, ValidationError} from "../../../api/api";
import {useRefresh, useRefreshEffect} from "../../RefreshController";
import {faDollarCircle, faDollar} from "@fortawesome/pro-light-svg-icons";
import {InputErrors} from "../../form/InputErrors";
import {formatTimeString} from "../../../util/formatTimeString";

export const TicketTimeTab: FC<{ ticket: Ticket }> = (props) => {
  const today = new Date().toISOString().split('T')[0];
  const tenant = useTenant()
  const [order, setOrder] = usePersistentState<'ASC'|'DESC'>('order', 'DESC');
  const sortedTimes = props.ticket.times
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  const orderedTimes = order === 'ASC' ? [...sortedTimes].reverse() : sortedTimes;
  const groupedTimes = orderedTimes.reduce((acc: Record<string, typeof props.ticket.times>, time) => {
    const dateKey = new Date(time.createdAt).toISOString().split('T')[0];
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(time);
    return acc;
  }, {});
  const {getTicketTimeCategories} = useApiCall()
  const ticketTimeCategories = useFetchedResource(() => getTicketTimeCategories())
  useRefreshEffect(() => {
    ticketTimeCategories.reload(undefined)
  })

  if (props.ticket.times.length === 0) {
    return <div className="border border-slate-200 rounded dark:border-zinc-500 bg-white dark:bg-zinc-700 space-x-2 flex flex-col items-center py-8">
      <FontAwesomeIcon icon={faClock} className={'text-3xl text-slate-600 dark:text-zinc-400'}/>
      <div className={'my-6 text-slate-800 dark:text-zinc-200'}>
        Er zijn nog geen tijden geschreven op dit ticket<br />
        <ol className={'list-decimal pl-6 mt-3 mb-3'}>
          <li>Zet de datum goed.</li>
          <li>Kies een post, dit kan een <NavLink to={`/${tenant?.tenant}/ticketflow`} className={'underline'}>categorie</NavLink> of klant-contract zijn.</li>
          <li>Vul je uren in, dit wordt afgerond op 5 minuten.</li>
          <li>Omschrijf je werkzaamheden.</li>
        </ol>
      </div>
      <div className={'w-full px-6'}>
        {ticketTimeCategories.resource && <InlineHoursAddForm ticket={props.ticket} categories={ticketTimeCategories.resource} />}
      </div>
    </div>
  }

  return <div>
    <div className={"flex justify-end"}>
      <IconDropdownButton icon={faArrowUpArrowDown} type={'ticket-table'} size={'sm'}
                          text={order === "DESC" ? 'Aflopend' : 'Oplopend'} data={{'ASC': 'Oplopend', 'DESC': 'Aflopend'}} onClick={() => setOrder(prevOrder => prevOrder === 'ASC' ? 'DESC' : 'ASC')}  />
    </div>
    {order === "DESC" && ticketTimeCategories.resource && <TicketTimeAdd ticket={props.ticket} orderBy={order} categories={ticketTimeCategories.resource}/>}
    {props.ticket.times.length > 0 ? (
      Object.entries(groupedTimes).map(([date, times], index) => (
        <div className={"mt-4"}  key={date}>
          <h2 className="font-semibold text-lg mb-1">
            {new Date(date).toLocaleDateString('nl-NL', {weekday: 'long', day: 'numeric', month: 'long',})}
          </h2>
          {times.map((time, i) => (
            <TicketTimeRow key={time.id || i} ticket={props.ticket} categories={ticketTimeCategories.resource ?? []} ticketTime={time} isFirst={i == 0} isLast={index === Object.entries(groupedTimes).length - 1 || i === times.length - 1}/>
          ))}

        </div>
      ))
    ) : (
      <div className="flex border border-slate-200 dark:border-zinc-500 bg-white dark:bg-zinc-700 space-x-2">
        <FontAwesomeIcon icon={faClock}/>
        <h1>Er zijn nog geen tijden geschreven op dit ticket</h1>
      </div>
    )}
    {order === "ASC" && ticketTimeCategories.resource && <TicketTimeAdd ticket={props.ticket} orderBy={order} categories={ticketTimeCategories.resource} />}
  </div>
};

const TicketTimeAdd: FC<{ ticket: Ticket, orderBy: string, categories: TicketTimeCategory[] }> = (props) => {
  return <div
    className={`flex flex-col items-center rounded border border-slate-200 dark:border-zinc-500 bg-white dark:bg-zinc-700 space-y-2 p-4 my-4`}>
    <InlineHoursAddForm ticket={props.ticket} categories={props.categories} />
  </div>
}

const TicketTimeRow: FC<{ ticket: Ticket, ticketTime: TicketTime, categories: TicketTimeCategory[], isFirst: boolean, isLast: boolean }> = (props) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const {open: deleteModal} = useModal({title: "Tijd verwijderen", body: <DeleteTicketTimeModal ticket={props.ticket} time={props.ticketTime} />, size: "md"})
  const {open: editModal} = useModal({title: "Tijd Bewerken", body: <EditTicketTimeModal ticket={props.ticket} time={props.ticketTime} />, size: "md"})
  const category = props.ticketTime.category_id ? props.categories.find(c => c.id === props.ticketTime.category_id) : undefined
  return <div
    className={`p-3 ${props.isFirst ? 'rounded-t border-t' : 'rounded-t-0'} ${props.isLast ? 'rounded-b' : ''} border-b border-l border-r border-slate-200 dark:border-zinc-500 bg-white dark:bg-zinc-700`}>
    <div className={"flex items-center"}>
      <div className={"w-64"}>
        <span className="py-1 px-1">{props.ticketTime.user_name}</span>
      </div>
      <div className={"flex-1"}>
        {category && <FontAwesomeIcon icon={category.isBillable ? faDollarCircle : faDollar} className={`mr-2 w-4 ${category.isBillable ? 'text-brand-500 dark:text-brand-500' : 'text-slate-600 dark:text-zinc-400'}`}/>}
        {category?.name ?? '-'}
      </div>
      <div className={"w-32"}>
        <span className="py-1 px-1">{formatTimeString(parseFloat(props.ticketTime.value))}</span>
      </div>
      <div className={"border rounded border-slate-200 dark:border-zinc-500 hover:dark:bg-zinc-600 px-2 py-1 hover:cursor-pointer"} onClick={() => setIsCollapsed(!isCollapsed)}>
        <FontAwesomeIcon icon={isCollapsed ? faChevronUp : faChevronDown}/>
      </div>
    </div>
    {isCollapsed &&
      <div>
        <div className={"flex pl-3 py-2 mt-3 rounded border border-slate-200 dark:border-zinc-600 text-sm text-slate-800 dark:text-zinc-200"}>
          {props.ticketTime.description}
        </div>
        <div className={"flex space-x-2 mt-2"}>
          <Button type={'primary'} size={'sm'} text={"Bewerk"} icon={faPencil} onClick={editModal}/>
          <Button type={'danger'} size={'sm'} text={"Verwijder"} icon={faTrash} onClick={deleteModal} />
        </div>
      </div>
    }
  </div>
}

const InlineHoursAddForm: FC<{ticket: Ticket, categories: TicketTimeCategory[]}> = (props) => {
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState<number>(1)
  const [category, setCategory] = useState('')
  const [description, setDescription] = useState('')
  const [errors, setErrors] = useState<ErrorBag>({})

  const options = props.categories.map(c => ({
    key: c.id,
    text: c.name,
    subText: c.code,
    leading: <FontAwesomeIcon icon={c.isBillable ? faDollarCircle : faDollar} className={`text-xs ${c.isBillable ? 'text-brand-500 dark:text-brand-500' : 'text-slate-600 dark:text-zinc-400'}`}/>
  })).sort((a, b) => a.subText.localeCompare(b.subText))
  const {addTicketTime} = useApiCall()
  const [loading, setLoading] = useState(false)
  const reload = useRefresh()
  const save = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    await addTicketTime(props.ticket.id, date, time, description, category).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    }).finally(() => setLoading(false))
    reload()
    setLoading(false)
  }
  return <form className={"flex items-end space-x-4 w-full"} onSubmit={save}>
    <div className={"w-40"}>
      <InputErrors errors={errors} field={'created_at'}/>
      <Input type={'date'} label={'Datum'} value={date} onChange={setDate}/>
    </div>
    <div className={"w-40"}>
      <InputErrors errors={errors} field={'category'}/>
      <FancyAutocomplete label={'Post'} value={category} onChange={(selection) => setCategory(selection)} options={options}/>
    </div>
    <div className={"w-40"}>
      <InputErrors errors={errors} field={'value'}/>
      <Input type={'time'} label={'Tijd'} value={time} onChange={setTime}/>
    </div>
    <div className={"flex-1"}>
      <InputErrors errors={errors} field={'description'}/>
      <Input type={'text'} label={'Omschrijving'} value={description} onChange={setDescription}/>
    </div>
    <div className={"w-32 flex flex-col items-stretch"}>
      {!loading ? <Button type={'primary'} submit size={'md'} text={"Toevoegen"}/> : <div className={'text-slate-600 dark:text-zinc-400 flex items-center justify-center h-10'}><FontAwesomeIcon spin={true} icon={faCircleNotch} /></div>}
    </div>
  </form>
}