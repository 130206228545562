

import React, {FC, FormEvent, useCallback, useEffect, useMemo, useState} from "react";
// import {Input} from "../components/form/Input";
// import {ModalFooter} from "../components/layout/ModalProvider";
// import {Select} from "../components/form/Select";
import {useRefresh, useRefreshEffect} from "../components/RefreshController";
import {ModalFooter, useModal} from "../components/layout/ModalProvider";
import {Customer, Tag, User} from "../api/dto";
import {useApiCall} from "../api/api";
import {IconButton} from "../components/form/Button";
import {
  faAdd,
  faCheck,
  faCircleNotch,
  faInbox,
  faMagnifyingGlass,
  faPencil,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getRandomColor} from "../pages/RelationDetails";
import {EditTagModal} from "./EditTagModal";
import {DeleteTagModal} from "./DeleteTagModal";
import {TagPill} from "../components/content/TagPill";
import {useFetchedResource} from "../api/APIContext";
// import {ErrorBag, useApiCall, ValidationError} from "../api/api";
// import {InputErrors} from "../components/form/InputErrors";
// import {SectionHeader} from "../components/content/SectionHeader";
// import {SectionDivider} from "../components/content/SectionDivider";

export const EditUserTagsModal: FC<{user: User, customer: Customer, tags: Tag[]}> = (props) => {
  const {addTag, getTags} = useApiCall()
  const refresh = useRefresh()
  const {resource: tagsResource, reload: reloadTags, loading} = useFetchedResource(() => getTags())
  useRefreshEffect(() => {
    reloadTags(undefined)
  })
  const [input, setInput] = useState('')
  const [saving, setSaving] = useState(false)
  const tags = tagsResource ??  []
  const add = async (e: FormEvent) => {
    e.preventDefault()
    if (input.length < 2) return
    setSaving(true)
    await addTag(input, getRandomColor())
    refresh()
    setSaving(false)
    setInput('')
  }
  // const customerTagIds = props.customer.tag_customers
  const orderedTags = tags
    .filter(tag => tag.name.toLowerCase().includes(input.toLowerCase()))
    .sort((a, b) => {
      const aChecked = props.user.tag_users.some(t => t.tag?.id === a.id)
      const bChecked = props.user.tag_users.some(t => t.tag?.id === b.id)
      if (aChecked && !bChecked) return -1
      if (!aChecked && bChecked) return 1
      return a.name.localeCompare(b.name);
    })
  return <div className={"pb-4"}>
    <form className={"flex space-x-3"} onSubmit={add}>
      <input type="text" placeholder={'Typ om te zoeken of toevoegen...'} disabled={saving}
             className={"flex-1 border border-brand-200 dark:border-brand-600 dark:bg-zinc-700 rounded-md p-2"}
             value={input} onChange={(e) => setInput(e.target.value)}/>
      <IconButton type={'primary'} disabled={input.length < 2 || saving} submit size={'md'} icon={faAdd}/>
    </form>
    {(!loading || tags.length > 0) && <div className={"h-[50vh] overflow-y-scroll"}>
      {orderedTags.map((tag, i) => {
        return <UserCheckEditorRow tag={tag} customer={props.customer} user={props.user} key={i}/>
      })}
    </div>}
    {loading && tags.length === 0 ? <>
      <div className={"py-8 flex flex-col items-center justify-center text-slate-500 dark:text-slate-300"}>
        <FontAwesomeIcon icon={faCircleNotch} className={"text-lg"} spin={true} />
        <span className={'font-medium text-lg mt-3'}>Tags laden</span>
      </div>
    </> : <>
      {orderedTags.length === 0 && <>
        {tags.length === 0 ? <>
          <div className={"py-8 flex flex-col items-center justify-center text-slate-500 dark:text-slate-300"}>
            <FontAwesomeIcon icon={faInbox} className={"text-lg"}/>
            <span className={'font-medium text-lg mt-3'}>Geen tags</span>
            {input.length < 2 ? <p>Start door een tag toe te voegen</p> :
              <p>Druk op Enter om <strong>{input}</strong> toe te voegen</p>}
          </div>
        </> : <>
          <div className={"py-8 flex flex-col items-center justify-center text-slate-500 dark:text-slate-300"}>
            <FontAwesomeIcon icon={faMagnifyingGlass} className={"text-lg"}/>
            <span className={'font-medium text-lg mt-3'}>Geen resultaten gevonden</span>
            {input.length < 2 ? <p>Geen tags gevonden</p> :
              <p>Druk op Enter om <strong>{input}</strong> toe te voegen</p>}
          </div>
        </>}
      </>}
    </>}
  </div>
}

const UserCheckEditorRow: FC<{ tag: Tag, customer: Customer, user: User }> = (props) => {
  const {addUserTag, deleteUserTag} = useApiCall()
  const reload = useRefresh()
  const [checked, setChecked] = useState(props.user.tag_users.some(t => t.tag?.id === props.tag.id))
  const userTag = useMemo(() => {
    return props.user.tag_users.find(t => t.tag?.id === props.tag.id);
  }, [props.tag.id, props.user])
  const toggleTag = useCallback(async () => {
    setChecked(!checked)
    if (checked) {
      await deleteUserTag(props.customer, props.user, props.tag, userTag?.id ?? 'huh?')
    } else {
      await addUserTag(props.customer, props.user, props.tag)
    }
    reload()
  }, [userTag, checked, props.user, props.tag])
  useEffect(() => {
    setChecked(props.user.tag_users.some(t => t.tag?.id === props.tag.id))
  }, [props.user.tag_users]);

  const editTagModal = useModal({title: 'Tag bewerken', body: <EditTagModal tag={props.tag}/>})
  const deleteTagModal = useModal({title: 'Tag verwijderen', body: <DeleteTagModal tag={props.tag}/>})

  return <div
    className={"rounded border border-slate-200 dark:border-zinc-600 hover:bg-slate-50 dark:hover:bg-zinc-600 group cursor-pointer h-10 px-3 flex items-stretch mt-3"}
  >
    <div className={"flex items-center flex-1"} onClick={() => toggleTag()}>
      <div className={`h-6 w-6 rounded-full border border-slate-200 mr-3 flex items-center justify-center ${checked ? 'bg-brand-800 border-brand-800 dark:bg-brand-500 dark:border-brand-500' : 'border-slate-200 dark:border-slate-500'}`}>
        <FontAwesomeIcon icon={faCheck} className={checked ? 'text-white dark:text-zinc-950' : "text-transparent group-hover:text-slate-300 dark:group-hover:text-slate-500"}/>
      </div>
      <div className={`flex-1`}>
        <TagPill size={'sm'} tag={props.tag} />
      </div>
    </div>
    <div className={"flex items-center"}>
      <div
        onClick={() => editTagModal.open()}
        className={"h-6 w-6 rounded-full hover:bg-brand-200 dark:hover:bg-brand-700 ml-1 flex items-center justify-center text-slate-600 dark:text-slate-400 hover:text-brand-600 dark:hover:text-brand-50"}>
        <FontAwesomeIcon icon={faPencil} className={"h-3"}/>
      </div>
      <div
        onClick={() => deleteTagModal.open()}
        className={"h-6 w-6 rounded-full hover:bg-red-200 dark:hover:bg-red-700 ml-1 flex items-center justify-center text-slate-600 dark:text-slate-400 hover:text-red-600 dark:hover:text-red-50"}>
        <FontAwesomeIcon icon={faTimes} className={"h-3"}/>
      </div>
    </div>
  </div>
}
